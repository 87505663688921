import styled from 'styled-components';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';

export type InputProps = InputBaseProps & { capitalize?: boolean };

export const Input = styled(({ capitalize, ...props }) => (
  <InputBase {...props} />
))<InputProps>`
  background: white;
  border: 1px solid ${({ error }) => (error ? '#e91d36' : '#ededee')};
  border-radius: 6px;
  height: ${(props) => (props.minRows ? 'auto' : '44px')};
  padding: 12px;
  transition: border-color 180ms cubic-bezier(0.4, 0, 0.2, 1);
  :focus-within,
  :active {
    border-width: 2px;
  }

  :focus-within,
  :active,
  :hover {
    border-color: ${({ error }) => (error ? '#e91d36' : '#5e84ff')};
  }

  input,
  textarea {
    font-size: 14px;
    font-weight: 400;
    height: auto;
    line-height: 16px;
    min-height: 16px;
    text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
    padding: 0;
    ::placeholder {
      color: #979797;
      font-size: 14px;
      line-height: 16px;
      opacity: 1;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-background-clip: text;
    }
  }
`;

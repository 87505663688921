import { ReactNode } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { InputIcon } from './components';
import { Input as UIInput, InputProps as UIInputProps } from './ui';
import { InputProps as MuiInputProps } from '@material-ui/core/Input';

export type InputProps = UIInputProps & {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
} & MuiInputProps;

export default function Input({
  leftIcon,
  rightIcon,
  error,
  ...inputBaseProps
}: InputProps) {
  return (
    <UIInput
      {...inputBaseProps}
      error={error}
      startAdornment={
        leftIcon ? (
          <InputIcon position="start" iconComponent={leftIcon} />
        ) : null
      }
      endAdornment={
        <>
          {error ? (
            <InputIcon
              position="end"
              iconComponent={<ErrorOutlineIcon color="error" />}
            />
          ) : null}
          {rightIcon ? (
            <InputIcon position="end" iconComponent={rightIcon} />
          ) : null}
        </>
      }
    />
  );
}

import styled from 'styled-components';

import { BP, GUTTERS } from 'layout/constants';

type ContentGridProps = {
  mainPosition?: string;
  secondaryPosition?: string;
};

const ContentGrid = styled.div`
  align-items: ${(props: ContentGridProps) => props.secondaryPosition};
  display: flex;
  flex-direction: column;
  gap: ${GUTTERS.xs}px;
  justify-content: ${(props: ContentGridProps) => props.mainPosition};

  @media (min-width: ${BP.sm}px) {
    gap: ${GUTTERS.sm}px;
  }

  @media (min-width: ${BP.md}px) {
    flex-direction: row;
    gap: ${GUTTERS.md}px;
  }

  @media (min-width: ${BP.lg}px) {
    gap: ${GUTTERS.lg}px;
  }

  @media (min-width: ${BP.xl}px) {
    gap: ${GUTTERS.xl}px;
  }
`;

export default ContentGrid;

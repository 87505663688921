import styled from 'styled-components';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';

const SuccessMessage = styled.div`
  align-items: center;
  color: #3cd070;
  display: flex;
  font-size: 14px;
  line-height: 18px;
`;

const SuccessMessageIcon = styled(SuccessIcon)`
  font-size: 18px;
  margin-right: 4px;
`;

interface MessageLabelToastProps {
  message: string;
}

export function MessageLabelToast({ message }: MessageLabelToastProps) {
  return (
    <SuccessMessage>
      <SuccessMessageIcon />
      {message}
    </SuccessMessage>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { triggerOnResolve } from 'features/alerts/utils';

export function useToast(duration: number = 5000) {
  const [isShown, setIsShown] = useState(false);
  const [lastCall, setLastCall] = useState(0);

  useEffect(() => {
    if (isShown) {
      const timeout = setTimeout(() => {
        setIsShown(false);
      }, duration);
      return () => clearTimeout(timeout);
    }
  }, [isShown, duration, lastCall]);

  const toast = useCallback(() => {
    setLastCall(Date.now());
    setIsShown(true);
  }, []);

  const toastOnResolve = triggerOnResolve(toast);

  return {
    toast,
    isShown,
    toastOnResolve
  };
}

import styled from 'styled-components';
import MuiCard, { CardProps } from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DocumentCheckedIcon } from 'shared/icons/DocumentCheckedIcon';

export const Card = styled((props: CardProps) => (
  <MuiCard elevation={0} {...props} />
))`
  border-radius: 12px;
  border: 1px solid #ededee;
  flex: 1;
  height: fit-content;
  max-width: initial;
  padding: 24px;
`;

export const PartnerCard = styled((props: CardProps) => (
  <MuiCard elevation={0} {...props} />
))`
  align-items: center;
  border: 1px solid #ededee;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  min-height: 64px;
  font-size:14px;
  padding: 0 16px 0 12px;
  & + & {
    margin-top: 12px;
  }
`;

export const PartnerCredentialCheckIcon = styled(({ mini, ...props }) => (
  <DocumentCheckedIcon {...props} fontSize="small" color="primary" />
))``;

export const PartnerName = styled(({ disabled, ...props }) => (
  <span {...props} />
))`
  align-items: center;
  color: ${({ disabled }) => (disabled ? '#979797' : '#000')};
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;

  & ${PartnerCredentialCheckIcon} {
    margin-left: 4px;
  }
`;

export const DeleteButton = styled((props) => (
  <DeleteRoundedIcon {...props} color="error" />
))`
  cursor: pointer;
  margin-left: 24px;
`;

export const CardTitle = styled((props) => (
  <Typography {...props} variant="body1" />
))`
  margin-bottom: 12px;
`;

export const PartnersListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const CardSubTitle = styled((props) => (
  <Typography {...props} variant="body2" />
))`
  margin-bottom: 24px;
  color: #000;
  line-height: 20px;
`;

export const LoadingSpinner = styled((props) => (
  <Grid {...props} container justifyContent="center">
    <CircularProgress />
  </Grid>
))`
  padding: 40px;
`;

export const WebhookUrlInfoContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const WebhookUrl = styled.div`
  align-items: center;
  background: #f7f7fc;
  border-radius: 8px;
  color: #5e84ff;
  display: flex;
  flex-basis: 366px;
  flex-grow: 1;
  height: 44px;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 12px 12px 0 12px;
  white-space: nowrap;
  ::-webkit-scrollbar {
    width: 4px;
  }
`;

export const TextWithIcon = styled.span`
  & > * {
    vertical-align: middle;
  }
  ${PartnerCredentialCheckIcon} {
    font-size: 16px;
    margin-left: 4px;
  }
`;

type AccentedTextProps = {
  color?: string;
  isBold?: boolean;
};

export const AccentedText = styled.span`
  color: ${({ color = 'inherit' }: AccentedTextProps) => color};
  font-weight: ${({ isBold = false }: AccentedTextProps) =>
    isBold ? '500' : 'inherit'};
`;

export const StaircaseContractLabel = styled.div`
  padding: 8px 20px;
  margin-right: -6px;
  font-size:14px;
  color: #bebebe;
`;

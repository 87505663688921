import { usePartnerActions } from 'pages/partners/hooks/usePartnerActions';
import { PartnersProducts } from 'api/partners';
import {
  ProductPartnersTabPanel,
  ProductPartnersTabPanelProps
} from './ProductPartnersTabPanel';

interface EmploymentPartnersTabPanelProps
  extends Pick<ProductPartnersTabPanelProps, 'index' | 'selectedIndex' | 'location'> {}

export function EmploymentPartnersTabPanel(props: EmploymentPartnersTabPanelProps) {
  const {
    isLoading: isLoadingEmploymentPartners,
    partners: employmentPartners,
    movePartner: moveEmploymentPartner,
    updatePartnerStatus: updateEmploymentPartnerStatus,
    addPartnerCredential,
    removePartnerCredentials,
    partnerWebhookUrl
  } = usePartnerActions(PartnersProducts.EMPLOYMENT, props.location);

  const productListSubTitle =
    '\nSelect the partners that you want to use to get employment verification results. \n' +
      'You can set the verification order using the arrows.';
  const productAboutDescription =
    'Select the partners that you want to use to get employment verification results. You can choose between using <span style="font-weight: 500;">Staircase\'s contract</span> with selected partners or <span style="font-weight: 500;">bringing your own contracts.</span>';

  return (
    <ProductPartnersTabPanel
      {...props}
      partnerWebhookUrl={partnerWebhookUrl}
      isLoading={isLoadingEmploymentPartners}
      productPartners={employmentPartners}
      productListSubTitle={productListSubTitle}
      productAboutDescription={productAboutDescription}
      onMovePartner={moveEmploymentPartner}
      onUpdatePartnerStatus={updateEmploymentPartnerStatus}
      onAddPartnerCredential={addPartnerCredential}
      onRemovePartnerCredentials={removePartnerCredentials}
    />
  );
}

import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Byte from "./pages/byte"

import VerificationStartPage from "./pages/VerificationStartPage";
import MultipleVerificationResultPage from "./pages/MultipleVerificationResultPage";
import SingleVerificationResultPage from "./pages/SingleVerificationResultPage";
import TransactionListPage from "./pages/TransactionListPage";
import VerificationStarted from "./pages/VerificationStartedPage";
import AssetVerificationStartPage from "./pages/AssetVerificationStartPage";
import AssetVerificationResultPage from "./pages/AssetVerificationResultPage";
import ExceptionCatchedPage from "./pages/ExceptionCatchedPage";
import PartnersPage from "./pages/partners/index.js";
import Header from "./pages/Header";
import host from "@elliemae/em-ssf-guest";

function App() {

  let customerName

  try {
    customerName = new URLSearchParams(window.location.search)?.get("customerName")
  }
  catch (error) {
    customerName = ""
  }

  if (customerName === "byte") {
    return (
      <Router><Byte /></Router>
    )
  }
  else {
    host.connect();
    return (
      <Router>
        <div className="App">
          <Header data={{ host: host }} />
          <Switch>
            <Route exact path="/">
              <VerificationStartPage data={{ host: host }} />
            </Route>
            <Route path="/result/multiple">
              <MultipleVerificationResultPage data={{ host: host }} />
            </Route>
            <Route path="/result/single">
              <SingleVerificationResultPage data={{ host: host }} />
            </Route>
            <Route path="/transactions">
              <TransactionListPage data={{ host: host }} />
            </Route>
            <Route path="/asset/result/single">
              <AssetVerificationResultPage data={{ host: host }} />
            </Route>
            <Route path="/result">
              <VerificationStarted data={{ host: host }} />
            </Route>
            <Route path="/error">
              <ExceptionCatchedPage data={{ host: host }} />
            </Route>
            <Route path="/partners">
              <PartnersPage data={{ host: host }} />
            </Route>
            <Route exact path="/asset">
              <AssetVerificationStartPage data={{ host: host }} />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
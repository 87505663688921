export const BP = {
  sm: 600,
  md: 905,
  lg: 1240,
  xl: 1440
};

export const CONTENT_SPACING = {
  xs: 16,
  sm: 16,
  md: 32,
  lg: 32,
  xl: 32
};

export const CONTENT_WIDTHS = {
  lg: 1032,
  md: 858
};

export const GUTTERS = {
  xs: 8,
  sm: 12,
  md: 12,
  lg: 16,
  xl: 16
};

export const NAV_HEIGHTS = {
  xs: 56,
  sm: 64,
  md: 64,
  lg: 64,
  xl: 64
};

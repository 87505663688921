import StaircaseLogo from "./../../images/logo_staircase.png";
import HelpIcon from "./../../images/icons/ic_help.png";

const Header = ({ params }) => {

  const customerName = params.customerName.toUpperCase()
  
  return (
      <div id="topBar">
          <div id="topLeftDiv">
              <p className="topBarText">
                  {customerName}
              </p>
          </div>
          <div id="topCenterDiv">
              <img id="staircase-icon" src={StaircaseLogo} alt="Staircase Icon"></img>
          </div>
          <div id="topRightDiv">
              <a href="https://form.asana.com/?k=YmKqs8aBMsHHsmtL9hXRPA&d=1170292918610401" target="_blank">
                  <p className="topBarText" style={{ order: 2 }}>
                      Support
                  </p>
              </a>
              <a href="https://form.asana.com/?k=YmKqs8aBMsHHsmtL9hXRPA&d=1170292918610401" target="_blank">
                  <img className="right_icon" src={HelpIcon} alt="Right Icon"></img>
              </a>
          </div>
      </div>
  );
}

export default Header;
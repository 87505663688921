import { useState } from 'react';
// import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import {
  Card,
  CardSubTitle,
  CardTitle,
  // PartnerCredentialCheckIcon,
  // TextWithIcon,
  // AccentedText
} from 'pages/partners/components/ui';
// import Button from 'shared/ui/button';
import { Partner, PartnerCredentials } from 'api/partners/types';
import { AddPartnerCredentialsModal } from './AddPartnerCredentialsModal';
import { ProductUrlModal } from './ProductUrlModal';

interface AboutCardProps {
  subTitle: string;
  partners: Partner[];
  onAddPartnerCredential?: (
    credentials: PartnerCredentials
  ) => Promise<unknown>;
  webhookUrl?: string;
}
export function AboutCard({
  subTitle,
  partners,
  onAddPartnerCredential,
  webhookUrl
}: AboutCardProps) {
  const [isAddModalShown, setIsAddModalShown] = useState(false);
  const [isUrlModalShown, setIsUrlModalShown] = useState(false);
  const handleAddPartnerCredential = async (
    credentials: PartnerCredentials
  ) => {
    return onAddPartnerCredential?.(credentials).then(() => {
      setIsUrlModalShown(true);
    });
  };

  return (
    <Card>
      <CardTitle>About</CardTitle><br />
      <CardSubTitle>
        <div dangerouslySetInnerHTML={{ __html: subTitle }} />

        {onAddPartnerCredential && (
          <>
            {/*<p>You can set the verification order using the arrows.</p>*/}

            {/*<p>*/}
            {/*  <span style={{ fontWeight: 500 }}>Staircase's Contracts</span>*/}
            {/*  <br />*/}
            {/*  Staircase has contracts with many partners that you can use. Those*/}
            {/*  partners are listed already, and you just have to enable them.*/}
            {/*</p>*/}

            {/*<p>*/}
            {/*  <span style={{ fontWeight: 500 }}>Use your Contracts</span>*/}
            {/*  <br />*/}
            {/*  Some partners require you to have a contract with them, and some*/}
            {/*  allow you to replace Staircase's contract with your own. In both*/}
            {/*  cases, you can use the button below to add your credentials and*/}
            {/*  bring your contract.*/}
            {/*</p>*/}

            {/*<TextWithIcon>*/}
            {/*  <span>*/}
            {/*    We indicate partners that are using your contract with this*/}
            {/*    icon:*/}
            {/*  </span>*/}
            {/*  <PartnerCredentialCheckIcon mini />*/}
            {/*</TextWithIcon>*/}

            {/*<p>*/}
            {/*  Use the "*/}
            {/*  <AccentedText color="#E91D36" isBold>*/}
            {/*    Remove Contract*/}
            {/*  </AccentedText>*/}
            {/*  " button to remove your credentials and keep Staircase's contract*/}
            {/*  if available.*/}
            {/*</p>*/}
            {/*<p>You can also add new partners using your own contract.</p>*/}
          </>
        )}
      </CardSubTitle>
      {onAddPartnerCredential && (
        <>
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  startIcon={<NoteAddOutlinedIcon />}*/}
          {/*  onClick={() => setIsAddModalShown(true)}*/}
          {/*>*/}
          {/*  Add Your Contract*/}
          {/*</Button>*/}
          <AddPartnerCredentialsModal
            partners={partners}
            open={isAddModalShown}
            onAdd={handleAddPartnerCredential}
            onClose={() => setIsAddModalShown(false)}
          />
          <ProductUrlModal
            url={webhookUrl || ''}
            open={isUrlModalShown}
            onClose={() => setIsUrlModalShown(false)}
          />
        </>
      )}
    </Card>
  );
}

import { FormEventHandler, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import { FormHelperTextProps as IFormHelperTextProps } from '@material-ui/core/FormHelperText';
import { InputLabelProps as IInputLabelProps } from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectProps } from '@material-ui/core/Select';
import Input, { InputProps } from 'shared/ui/input';
import { PropType } from 'shared/utils/types';

import {
  TextFieldFormHelperText,
  TextFieldInputLabel,
  TextFieldSelect,
  TextFieldSelectExpandMoreIcon,
  FormControl
} from './ui';

import { TextFieldOption } from './types';

export type GenericOnChange = PropType<InputProps, 'onChange'> &
  PropType<SelectProps, 'onChange'>;

export interface TextFieldProps extends Omit<InputProps, 'onChange'> {
  debounceOnChange?: number;
  helperText?: string;
  label: string;
  InputLabelProps?: IInputLabelProps;
  FormHelperTextProps?: IFormHelperTextProps;
  select?: boolean;
  options?: TextFieldOption[];
  onChange?: GenericOnChange;
  size?: 'small' | 'medium'; // TODO: implement small size
  fullWidth?: boolean;
  capitalize?: boolean;
  hiddenLabel?: boolean;
}

export default function TextField({
  helperText,
  label,
  id,
  style,
  InputLabelProps,
  FormHelperTextProps,
  error,
  select,
  options,
  rightIcon,
  fullWidth,
  hiddenLabel,
  className,
  size = 'medium',
  debounceOnChange,
  onChange,
  onBlur,
  ...inputBaseProps
}: TextFieldProps) {
  const iconColor = error ? 'error' : 'primary';
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const debouncedNativeOnChange = useMemo(() => {
    if (inputBaseProps?.inputProps?.onChange) {
      return debounce(inputBaseProps.inputProps.onChange, debounceOnChange);
    }
  }, [inputBaseProps?.inputProps?.onChange, debounceOnChange]);

  const debouncedOnChange = useMemo(() => {
    if (onChange) {
      return debounce(onChange, debounceOnChange);
    }
  }, [onChange, debounceOnChange]);

  return (
    <FormControl className={className} style={style} fullWidth={fullWidth}>
      <TextFieldInputLabel
        hidden={!label || hiddenLabel}
        htmlFor={id}
        error={error}
        shrink
        focused={false}
      >
        {label}
      </TextFieldInputLabel>

      {select ? (
        <TextFieldSelect
          {...inputBaseProps}
          onChange={onChange}
          id={id}
          error={error}
          open={isSelectOpen}
          onClose={() => setIsSelectOpen(false)}
          onOpen={() => setIsSelectOpen(true)}
          IconComponent={() => <></>}
          rightIcon={
            <TextFieldSelectExpandMoreIcon
              onClick={() => setIsSelectOpen((open) => !open)}
              color={iconColor}
            />
          }
          input={<Input />}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextFieldSelect>
      ) : (
        <Input
          {...inputBaseProps}
          id={id}
          onChange={
            debouncedOnChange
              ? onChange
              : (debouncedOnChange as unknown as GenericOnChange)
          }
          error={error}
          inputProps={{
            ...inputBaseProps.inputProps,
            ...(debouncedNativeOnChange && {
              onChange: debouncedNativeOnChange as FormEventHandler
            })
          }}
          rightIcon={rightIcon}
        />
      )}
      <TextFieldFormHelperText id={id} error={error} {...FormHelperTextProps}>
        {helperText}
      </TextFieldFormHelperText>
    </FormControl>
  );
}

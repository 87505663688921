import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import logoWhite from 'assets/images/logo.svg';
import { LogOutModal } from 'components/log-out-modal';
import Menu from './components/Menu';

const StyledLink = styled(Link)`
  display: flex;
`;

export const Header = () => {
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const actions = [
    { label: 'Log out', callback: () => setIsLogOutModalOpen(true) }
  ];
  return (
    <>
      <LogOutModal
        open={isLogOutModalOpen}
        onClose={() => setIsLogOutModalOpen(false)}
      />
      <AppBar position="fixed">
        <Toolbar style={{ background: 'black' }}>
          <Menu actions={actions} />
          <StyledLink to="/">
            <img alt="logo" src={logoWhite} />
          </StyledLink>
        </Toolbar>
      </AppBar>
    </>
  );
};

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
export function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 72 72" fill="none">
      <path
        d="M53.117 23.117C51.9478 21.9478 50.0522 21.9478 48.883 23.117C47.7153 24.2847 47.7135 26.1772 48.8789 27.347L54.51 33H27C25.3431 33 24 34.3431 24 36C24 37.6569 25.3431 39 27 39H54.51L48.8838 44.6262C47.7152 45.7948 47.713 47.6897 48.8775 48.8625C50.0479 50.0411 51.9543 50.0457 53.1288 48.8712L63.8787 38.1213C65.0503 36.9497 65.0503 35.0503 63.8787 33.8787L53.117 23.117ZM12 15H33C34.6569 15 36 13.6569 36 12C36 10.3431 34.6569 9 33 9H12C8.7 9 6 11.7 6 15V57C6 60.3 8.7 63 12 63H33C34.6569 63 36 61.6569 36 60C36 58.3431 34.6569 57 33 57H12V15Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

function getAmericanDateFromDate(dateString) {
  const timestamp = Date.parse(dateString);
  var date = new Date(timestamp);
  return (date.getMonth() +1 ) + "-" + date.getDate() + "-" + date.getFullYear();
}

function toUpperFirstChar(str) {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export { getAmericanDateFromDate, toUpperFirstChar };

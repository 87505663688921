import { Fragment } from 'react';

import ContentContainer from 'layout/components/ContentContainer';
import ContentStack from 'layout/components/ContentStack';
import { Container, Title, Back } from './ui';


type ContentTile = {
  title: string,
  backTo: string,
  backTitle: string,
  spaced?: boolean,
};


const ContentTitle = ({
  title,
  backTo,
  backTitle,
  spaced = false,
}: ContentTile) => {
  const RContainer = spaced ? ContentStack : Fragment;

  return(
   <RContainer>
     <Container>
      <ContentContainer>
        <Back
          size="medium"
          text={backTitle}
          to={backTo}
        />
    
        <Title variant="h2">{ title }</Title>
      </ContentContainer>
     </Container>
   </RContainer>
 );
};


export default ContentTitle;

import styled from 'styled-components';

import FooterImg from 'assets/images/staircase-stairs-footer.png';
import { BP, NAV_HEIGHTS } from 'layout/constants';

const PageContainer = styled.div`
  background-color: #fafafa;
  background-image: url(${FooterImg});
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100vh;
  overflow-y: auto;
  padding: ${NAV_HEIGHTS.xs}px 0 0 0;

  @media (min-width: ${BP.sm}px) {
    padding: ${NAV_HEIGHTS.sm}px 0 0 0;
  }

  @media (min-width: ${BP.md}px) {
    padding: ${NAV_HEIGHTS.md}px 0 0 0;
  }

  @media (min-width: ${BP.lg}px) {
    padding: ${NAV_HEIGHTS.lg}px 0 0 0;
  }

  @media (min-width: ${BP.xl}px) {
    padding: ${NAV_HEIGHTS.xl}px 0 0 0;
  }
`;

export default PageContainer;

import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { ModalProps } from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { ModalContent } from './ui';

interface ModalTitleProps extends Pick<ModalProps, 'onClose'> {
  centered?: boolean;
}

export const ModalTitleContainer = styled.div<ModalTitleProps>`
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  padding: 24px;

  h4 {
    flex-grow: 1;
    font-weight: 500;
    margin: 0;
    text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  }

  & + ${ModalContent} {
    padding-top: 0;
  }
`;

const CloseButton = styled((props) => (
  <IconButton {...props} aria-label="close" disableRipple color="inherit">
    <CloseRoundedIcon />
  </IconButton>
))`
  padding: 0;
  &:hover {
    background: inherit;
  }
`;

export function ModalTitle({
  onClose,
  ...props
}: PropsWithChildren<ModalTitleProps>) {
  return (
    <ModalTitleContainer {...props}>
      <h4>{props.children}</h4>
      {onClose && <CloseButton onClick={onClose} />}
    </ModalTitleContainer>
  );
}

import noop from 'lodash/noop';
import styled from 'styled-components';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

const ReorderButtonsContainer = styled.div`
  display: flex;
  padding: 7px 8px;
  flex-direction: column;
`;

const UpIconButton = styled(KeyboardArrowUpRoundedIcon)`
  cursor: ${(props) =>
    props.color === 'disabled' ? 'not-allowed' : 'pointer'};
`;

const DownIconButton = styled(KeyboardArrowDownRoundedIcon)`
  cursor: ${(props) =>
    props.color === 'disabled' ? 'not-allowed' : 'pointer'};
`;

export const ReorderButtons = ({
  onMoveUp,
  onMoveDown,
  disableMoveUp,
  disableMoveDown
}: any) => {
  return (
    <ReorderButtonsContainer>
      <UpIconButton
        onClick={disableMoveUp ? noop : onMoveUp}
        color={disableMoveUp ? 'disabled' : 'primary'}
      />
      <DownIconButton
        onClick={disableMoveDown ? noop : onMoveDown}
        color={disableMoveDown ? 'disabled' : 'primary'}
      />
    </ReorderButtonsContainer>
  );
};

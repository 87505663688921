export enum ProductGroup {
  DATA_MANAGER = 'Data Manager',
  BORROWER = 'Borrower',
  COLLATERAL = 'Collateral',
  LOAN = 'Loan',
  FULFILLMENT = 'Fulfillment',
  ADAPTERS = 'Adapters',
  // APPLICATION = 'Application',
  UNDERWRITING = 'Underwriting',
  // CLOSING = 'Closing',
  // SERVICING = 'Servicing',
  // CAPITAL_MARKETS = 'Capital Markets',
  INTEGRATE = 'Integrate',
  DATA = 'Data',
  DISTRIBUTE = 'Distribute',
  SHIP = 'Ship'
}

export const ActiveProductNameGroupMap: {
  [key: string]: ProductGroup;
} = {
  'Document Manager': ProductGroup.DATA_MANAGER,
  'Document Classification': ProductGroup.DATA_MANAGER,
  'Data Extraction': ProductGroup.DATA_MANAGER,
  'GroundTruth Labeling': ProductGroup.DATA_MANAGER,

  Employment: ProductGroup.BORROWER,
  Credit: ProductGroup.BORROWER,
  Income: ProductGroup.BORROWER,
  Assets: ProductGroup.BORROWER,
  Identity: ProductGroup.BORROWER,
  'Employment Router': ProductGroup.BORROWER,

  'Automated Valuation Model (AVM)': ProductGroup.COLLATERAL,
  Appraisal: ProductGroup.COLLATERAL,
  'Property Taxes': ProductGroup.COLLATERAL,
  Insurance: ProductGroup.COLLATERAL,

  // Options: ProductGroup.LOAN, //NOT FOUND
  Pricing: ProductGroup.LOAN,
  Fees: ProductGroup.LOAN,
  Compliance: ProductGroup.LOAN,
  Fraud: ProductGroup.LOAN,

  'Document Creation': ProductGroup.FULFILLMENT,
  eSign: ProductGroup.FULFILLMENT,
  eNotary: ProductGroup.FULFILLMENT,
  Title: ProductGroup.FULFILLMENT,
  'Title Insurance': ProductGroup.FULFILLMENT,

  LOS: ProductGroup.ADAPTERS,
  'Point of Sale (POS)': ProductGroup.ADAPTERS,
  'Servicing Software': ProductGroup.ADAPTERS,

  // 'Lead Generation': ProductGroup.APPLICATION, //NOTFOUND
  // 'Pre-Approval': ProductGroup.APPLICATION, //NOTFOUND
  // 'Pre-Qualification Software': ProductGroup.APPLICATION, //NOTFOUND

  // 'Loan Eligibility': ProductGroup.UNDERWRITING, //NOTFOUND
  'Automated Underwriting System (AUS)': ProductGroup.UNDERWRITING,
  // 'Collateral Underwriting': ProductGroup.UNDERWRITING, //NOT FOUND
  // 'Title Underwriting': ProductGroup.UNDERWRITING, // NOT FOUND
  'Mortgage Insurance': ProductGroup.UNDERWRITING,

  // 'Title Transfer': ProductGroup.CLOSING, //NOT FOUND

  // Loanboarding: ProductGroup.SERVICING, //NOT FOUND
  // 'Loan Modification': ProductGroup.SERVICING, //NOT FOUND
  // 'Loan Sale': ProductGroup.SERVICING, //NOT FOUND
  // Billing: ProductGroup.SERVICING, //NOT FOUND
  // 'Default Management': ProductGroup.SERVICING, //NOT FOUND
  // 'Escrow Management': ProductGroup.SERVICING, //NOT FOUND

  // 'Performance Management': ProductGroup.CAPITAL_MARKETS, //NOT FOUND
  // Rating: ProductGroup.CAPITAL_MARKETS, //NOT FOUND
  // Purchase: ProductGroup.CAPITAL_MARKETS, //NOT FOUND

  Connector: ProductGroup.INTEGRATE,
  Job: ProductGroup.INTEGRATE,
  Product: ProductGroup.INTEGRATE,

  Persistence: ProductGroup.DATA,
  'Data Share': ProductGroup.DATA,
  // 'Data Extraction Training': ProductGroup.DATA, //NOT FOUND
  Translator: ProductGroup.DATA,
  Language: ProductGroup.DATA,
  Rule: ProductGroup.DATA,

  Environment: ProductGroup.DISTRIBUTE,
  Account: ProductGroup.DISTRIBUTE,
  Marketplace: ProductGroup.DISTRIBUTE,
  Documentation: ProductGroup.DISTRIBUTE,
  Host: ProductGroup.DISTRIBUTE,
  // Company: ProductGroup.DISTRIBUTE, //NOT FOUND
  Setup: ProductGroup.DISTRIBUTE,
  DocumentAI: ProductGroup.DISTRIBUTE,
  // 'Host DocumentAI': ProductGroup.DISTRIBUTE, //NOT  FOUND
  SoftworksAI: ProductGroup.DISTRIBUTE,

  Code: ProductGroup.SHIP,
  Assess: ProductGroup.SHIP,
  Build: ProductGroup.SHIP,
  Deploy: ProductGroup.SHIP,
  Test: ProductGroup.SHIP,
  Health: ProductGroup.SHIP,
  Comply: ProductGroup.SHIP,
  Pipeline: ProductGroup.SHIP,
  Work: ProductGroup.SHIP
};

import { DialogProps as MuiModalProps } from '@material-ui/core/Dialog';
import { Backdrop, ModalContainer } from './components/ui';

export { ModalTitle } from './components/ModalTitle';
export { ModalButtons, ModalContent } from './components/ui';

export interface ModalProps extends MuiModalProps {
  title?: string;
  description?: string;
  minWidth?: number;
}

export function Modal({
  children,
  title,
  description,
  open,
  ...props
}: ModalProps) {
  return (
    <ModalContainer
      open={open}
      aria-labelledby={title}
      aria-describedby={description}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250
      }}
      {...props}
    >
      {children}
    </ModalContainer>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { Partner, PartnerCredentials } from 'api/partners/types';
import {
  getPartners,
  PartnersProducts,
  updatePartnersOrdering,
  updatePartnerStatus as updatePartnerStatusRequest,
  addPartnerCredential as addPartnerCredentialRequest,
  removePartnerCredentials as removePartnerCredentialsRequest
} from 'api/partners';
import cloneDeep from 'lodash/cloneDeep';
import { moveElement } from 'shared/utils/arrays';
import {
  fixPartnersOrdering,
  sortByOrder,
  normalizeRawPartners
} from 'pages/partners/utils';
import { useAuthState } from 'contexts/AuthContext';

const PRODUCT_WEBHOOK_URL_TEMPLATE: {
  [key in PartnersProducts]: string;
} = {
  [PartnersProducts.EMPLOYMENT]:
    'https://[USER_SUBDOMAIN_HOST].staircaseapi.com/connector-jobs/vendors/truework/flows/VOE/webhook',
  [PartnersProducts.INCOME]:
    'https://[USER_SUBDOMAIN_HOST].staircaseapi.com/connector-jobs/vendors/truework/flows/income2/webhook',
  [PartnersProducts.ASSETS]: '',
  [PartnersProducts.DATA_EXTRACTION]: '',
  [PartnersProducts.DOCUMENT_CLASSIFICATION]: ''
};

export function usePartnerActions(productName: PartnersProducts, state) {
  const [isLoading, setLoading] = useState(true);
  const [partners, setPartners] = useState<Partner[]>([]);
  const { user } = useAuthState();
  const userSubDomain = user?.subDomain || '';
  let domain = state.state.domain
  let api_key = state.state.apiKey
  const fetchPartners = useCallback(
    () =>
      getPartners(productName, domain, api_key).then((rawPartners) =>
        setPartners(normalizeRawPartners(rawPartners))
      ),
    [productName]
  );
  useEffect(() => {
    fetchPartners().finally(() => setLoading(false));
  }, [fetchPartners]);

  const updatePartnerStatus = useCallback(
    (partner: string, status: boolean) => {
      const currentPartners = partners;
      const newPartners = cloneDeep(partners) as Partner[];
      const updatedPartnerIndex = currentPartners.findIndex(
        (e) => e.partner === partner
      );
      newPartners[updatedPartnerIndex].active = status;

      setPartners(newPartners);

      return updatePartnerStatusRequest(partner, status, productName, domain, api_key).catch(
        (err) => {
          setPartners(currentPartners);
          throw err;
        }
      );
    },
    [partners, productName]
  );

  const addPartnerCredential = useCallback(
    (credentials: PartnerCredentials) => {
      const currentPartners = partners;
      const newPartners = cloneDeep(partners) as Partner[];
      const updatedPartnerIndex = currentPartners.findIndex(
        (e) => e.partner === credentials.partner
      );
      newPartners[updatedPartnerIndex].hasCredentials = true;
      newPartners[updatedPartnerIndex].active = true;

      setPartners(newPartners);

      return addPartnerCredentialRequest(credentials, productName).catch(
        (err) => {
          setPartners(currentPartners);
          throw err;
        }
      );
    },
    [partners, productName]
  );

  const removePartnerCredentials = useCallback(
    (partner: string, password: string) => {
      const currentPartners = partners;
      const newPartners = cloneDeep(partners) as Partner[];
      const updatePartnerIndex = currentPartners.findIndex(
        (e) => e.partner === partner
      );
      newPartners[updatePartnerIndex].hasCredentials = false;

      setPartners(newPartners);

      return removePartnerCredentialsRequest(
        partner,
        password,
        productName
      ).catch((err) => {
        setPartners(currentPartners);
        throw err;
      });
    },
    [partners, productName]
  );

  const movePartner = useCallback(
    (from: number, to: number) => {
      let fromIndex = from - 1;
      let toIndex = to - 1;
      const currentOrderedPartners = [...partners].sort(sortByOrder);

      // const isIncreasingIndex = fromIndex < toIndex;
      // if (isIncreasingIndex) {
      //   while (toIndex < partners.length && !partners[toIndex].active) {
      //     toIndex++;
      //   }
      // } else {
      //   while (toIndex > -1 && !partners[toIndex].active) {
      //     toIndex--;
      //   }
      // }

      const reOrderedPartners = fixPartnersOrdering(
        moveElement(currentOrderedPartners, fromIndex, toIndex) as Partner[]
      );

      setPartners(reOrderedPartners);

      return updatePartnersOrdering(
        reOrderedPartners.map(({ partner, order }) => ({ partner, order })),
        productName,
          domain,
          api_key
      ).catch((err) => {
        setPartners(currentOrderedPartners);
        throw err;
      });
    },
    [productName, partners]
  );

  const orderedPartners = [...partners].sort(sortByOrder);

  const getPartnerWebhookUrl = useCallback(() => {
    const userSubDomainHost = userSubDomain.replace('.staircaseapi.com', '');
    return PRODUCT_WEBHOOK_URL_TEMPLATE[productName].replace(
      '[USER_SUBDOMAIN_HOST]',
      userSubDomainHost
    );
  }, [productName, userSubDomain]);

  return {
    partners: orderedPartners,
    isLoading,
    updatePartnerStatus,
    movePartner,
    addPartnerCredential,
    removePartnerCredentials,
    partnerWebhookUrl: getPartnerWebhookUrl()
  };
}

import styled from 'styled-components';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import FormControlLabel, {
  FormControlLabelProps
} from '@material-ui/core/FormControlLabel';

const smallSizeThumb = 20;
const StyledSwitch = styled(Switch)`
  opacity: ${(props) => (props.disabled ? '0.38' : 1)};
  padding: 0;
  overflow: visible;
  align-items: center;

  .MuiSwitch-track {
    width: 34px;
    height: 14px;
    background-color: #2c2c2c;
    opacity: 0.08;
    border-radius: 7px;
  }

  .MuiSwitch-thumb {
    width: ${smallSizeThumb}px;
    height: ${smallSizeThumb}px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 1px rgba(0, 0, 0, 0.08),
      0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .MuiSwitch-switchBase {
    color: #fafafa;
    padding: 2px 2px 0 0;

    :hover {
      background: transparent;
    }

    &.Mui-checked {
      color: #3cd070;
      transform: translateX(14px);

      & + .MuiSwitch-track {
        background-color: #3cd070;
        opacity: 0.38;
      }
    }
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 20px;
    margin-right: 8px;
  }
`;

interface ToggleProps
  extends SwitchProps,
    Pick<FormControlLabelProps, 'label'> {}
export default function Toggle({
  checked,
  className,
  onChange,
  name,
  disabled,
  label,
  role
}: ToggleProps) {
  return (
    <StyledFormControlLabel
      role={role}
      className={className}
      labelPlacement="start"
      control={
        <StyledSwitch
          size="small"
          disableRipple
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          name={name}
        />
      }
      label={label}
    />
  );
}

import MuiTab, { TabProps as MuiTabProps } from '@material-ui/core/Tab';
import styled from 'styled-components';

interface TabProps extends MuiTabProps {}

const StyledMuiTab = styled(MuiTab)<TabProps>`
  text-transform: none;
  font-size: 14px;
  color: #2c2c2c;
  padding: 14px 12px;
`;

export default function Tab(props: TabProps) {
  return <StyledMuiTab disableRipple {...props} />;
}

export const PageTabContainer = styled.div`
  background: white;
  border-bottom: 1px solid #ededee;
`;
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { BackButton } from 'shared/components/back-button';


export const Container = styled.div`
  background: white;
  border-bottom: 1px solid #ededee;
  padding: 32px 0;
  text-align: center;

  @media (min-width: 960px) {
    padding: 54px 0;
    text-align: left;
  }
`;

export const Title = styled(Typography)`

`;

export const Back = styled(BackButton)`
  line-height: 24px;
  margin-bottom: 12px;
`;

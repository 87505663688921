import { MouseEventHandler, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalButtons, ModalProps, ModalTitle } from 'shared/ui/modal';
import { useSignOut } from 'contexts/AuthContext';

import { OrangeLogoutIcon, LogOutModalButton } from './components/ui';

export function LogOutModal(props: ModalProps) {
  const signOut = useSignOut();
  const history = useHistory();
  const logOut = useCallback(() => {
    signOut();
    history.push('/sign-in');
  }, [history, signOut]);

  const closeModal: MouseEventHandler<HTMLButtonElement> = (e) =>
    props.onClose?.(e, 'backdropClick');

  return (
    <Modal {...props} minWidth={402}>
      <OrangeLogoutIcon fontSize="large" />
      <ModalTitle centered>Do you Want to Log Out?</ModalTitle>
      <ModalButtons>
        <LogOutModalButton
          color="secondary"
          variant="text"
          onClick={closeModal}
        >
          Cancel
        </LogOutModalButton>
        <LogOutModalButton variant="contained" onClick={logOut}>
          Log Out
        </LogOutModalButton>
      </ModalButtons>
    </Modal>
  );
}

import styled from 'styled-components';
import { GridProps } from '@material-ui/core/Grid';
import MuiBackdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import { ModalProps } from 'shared/ui/modal';

export const ModalButtons = styled.div<Pick<GridProps, 'justifyContent'>>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  width: 100%;
  padding: 24px;

  button + button {
    margin-left: 16px;
  }
`;

export const ModalContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 24px;

  & + ${ModalButtons} {
    padding-top: 0;
  }
`;

export const ModalContainer = styled(Dialog)<ModalProps>`
  .MuiDialog-paper {
    min-width: ${({ minWidth }) => minWidth || 277}px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04),
      0 0 1px rgba(0, 0, 0, 0.04);
  }
`;

export const Backdrop = styled(MuiBackdrop)`
  background: rgba(0, 0, 0, 0.4);
`;

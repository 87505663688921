import React, {useEffect, useState} from "react";
import _ from "lodash";
import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {createTransaction, initializeOriginationContext,} from "../service/encompassService";
import {checkRequiredFields} from "../util/requiredFields";
import {create_health_metric} from "../service/healthService";
import { getPartners } from "../service/staircaseService";
import InfoIcon from "../images/icons/ic_info.png";
import ArrowRight from "../images/icons/arrow_right.png";

import "../App.css";
import { useHistory } from 'react-router-dom';


let verificationType = "default"

async function closeInteraction(host) {
  const transactionObject = await host.getObject("transaction");
  transactionObject.close();
}

function VerificationStartPage({ data }) {
  let transactionRequests = [];

  const borrowerLanguage = "encompass-to-staircase-borrower-mapping",
    coborrowerLanguage = "encompass-to-staircase-co-borrower-mapping",
    history = useHistory(),
    { host } = data;

  const [encompassOriginResult, setEncompassOriginResult] = useState([]);
  const [originationContext, setOriginationContext] = useState([]);
  const [transactionObject, setTransactionObject] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [verifButtonClicked, setVerifButtonClicked] = useState(false);
  const [verificationButtonEnabled, setVerificationButtonEnabled] = useState(false);
  const [isBorrowerEnabled, setIsBorrowerEnabled] = useState([]);
  const [isCoborrowerEnabled, setIsCoborrowerEnabled] = useState([]);
  const [borrowerEmployments, setBorrowerEmployments] = useState([]);
  const [coborrowerEmployments, setCoborrowerEmployments] = useState([]);
  const [partners, setPartners] = useState([]);
  const [verificationTypeLabel, setVerificationTypeLabel] = useState("Income Verification");
  const [pdfChecked, setPdfChecked] = useState(false);
  const [staircaseReportChecked, setStaircaseReportChecked] = useState(true);
  const [partnerReportChecked, setPartnerReportChecked] = useState(false);
  const [pdfFilename, setPdfFilename] = useState("");
  const [userSelectedResources, setUserSelectedResources] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("default");
  // const [verificationType, setVerificationType] = "income"


  useEffect(() => {
    const initVerification = async () => {
      let initResult;
      initResult = await initializeOriginationContext(host);
      setEncompassOriginResult(initResult.encompassOriginResult);
      setOriginationContext(initResult.originationContext);
      setTransactionObject(initResult.transactionObject);
      const domain = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "response_payload.credentials.domain",
        ""
      );
      const apiKey = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "response_payload.credentials.api_key",
        ""
      );
      let transaction_id = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "encompassOriginData.transaction_id",
        ""
      );
      let apps = _.get(initResult.encompassOriginResult, "encompassOriginData.response_payload.loan.applications")
      let borEmployments = [];
      let coBorEmployments = [];
      let borrowerEnabledStart = [];
      for(let app_index in apps){
        borrowerEnabledStart[app_index] = false
        borEmployments[app_index] = [];
        for(let employment_index in apps[app_index].borrower.employment){
          borEmployments[app_index][employment_index] = apps[app_index].borrower.employment[employment_index];
          borEmployments[app_index][employment_index].status = false;
        }
        coBorEmployments[app_index] = [];
        for(let employment_index in apps[app_index].coborrower.employment){
          coBorEmployments[app_index][employment_index] = apps[app_index].coborrower.employment[employment_index];
          coBorEmployments[app_index][employment_index].status = false;
        }
      }
      setBorrowerEmployments(borEmployments);
      setCoborrowerEmployments(coBorEmployments);
      setIsBorrowerEnabled(borrowerEnabledStart);
      setIsCoborrowerEnabled(borrowerEnabledStart);
      updateVerificationType(initResult.encompassOriginResult.encompassOriginData);
      updatePartners(domain, apiKey);
      setLoading(false);
      await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Opened the Encompass UI page, page successfully loaded", transaction_id, "/")
    };
    initVerification().catch((error)=> {
      history.push({
          pathname: "/error",
          state: {
            error:error.message,
            domain: domain,
            apiKey: apiKey
          },
        });
    });
  }, []);


  let { encompassOriginData } = encompassOriginResult;


  const domain = _.get(
    encompassOriginData,
    "response_payload.credentials.domain",
    ""
  );
  const apiKey = _.get(
    encompassOriginData,
    "response_payload.credentials.api_key",
    ""
  );
  function redirect () {
    console.log("BEFORE SENDING", verificationType)
    if (verificationType !== "default") {
      history.push({
        pathname: "/result/single",
        state: {
          transactionRequests: [
            {
              transactionId: originationContext.transactionId,
            },
          ],
          domain,
          apiKey,
          verificationType,
        },
      });
    } else {
      setTimeout(redirect, 300); // try again in 300 milliseconds
    }
  }

  if (originationContext && originationContext.transactionId) {
    redirect();
  }

  const verificationButtonClicked = async function () {
    setVerifButtonClicked(true);
    var selectedPartner = document.getElementById("partnersSelect").value;
    console.log(`Creating ${verificationType}`);
    console.log("borrowerEmployments", borrowerEmployments);
    console.log("coborrowerEmployments", coborrowerEmployments);
    console.log("isBorrowerEnabled", isBorrowerEnabled);
    console.log(encompassOriginResult);


    const promiseArray = [];

    for (let index in isBorrowerEnabled) {
      if (isBorrowerEnabled[index]) {
        for (const boEmp of borrowerEmployments[index]) {
          if (boEmp.status === true) {
            let requestObject = {
              request: {
                type: verificationTypeLabel,
                options: {
                  languageType: borrowerLanguage,
                  employmentId: boEmp.id,
                  loanIndex: index,
                  employerName: boEmp.employerName,
                  borrowerType: "borrower",
                  uploadStaircaseReport: staircaseReportChecked,
                  uploadPartnerReport: partnerReportChecked,
                  partner_name: selectedPartner,
                },
              },
            };
            if(userSelectedResources.length > 0){
              requestObject.request.resources = userSelectedResources;
            }
            console.log(requestObject)
            promiseArray.push(createTransaction(requestObject, transactionObject, encompassOriginResult));
            await new Promise((r) => setTimeout(r, 200));
          }
        }
      }
    }

    for (let index in isCoborrowerEnabled) {
      if (isCoborrowerEnabled[index]) {
        for (const coEmp of coborrowerEmployments[index]) {
          if (coEmp.status === true) {
            let requestObject = {
              request: {
                type: verificationTypeLabel,
                options: {
                  languageType: coborrowerLanguage,
                  employmentId: coEmp.id,
                  loanIndex: index,
                  employerName: coEmp.employerName,
                  borrowerType: "coborrower",
                  partner_name: selectedPartner,
                },
              },
            };
            if(userSelectedResources.length > 0){
              requestObject.request.resources = userSelectedResources;
            }
            console.log(requestObject)
            promiseArray.push(createTransaction(requestObject, transactionObject, encompassOriginResult));
            await new Promise((r) => setTimeout(r, 200));
          }
        }
      }
    }

    transactionRequests = await Promise.all(promiseArray);
    console.log(transactionRequests)
    await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Ordered the verification", "", "/")

    history.push({
      pathname: "/result",
      state: {
        transactionRequests,
        domain,
        apiKey,
        verificationType,
      },
    });
  };

  const openPdfSelectWindow = async function(){
    // setPdfWindowOpen(true);
    if(pdfChecked){
      setPdfChecked(false);
      setPdfFilename("");
      setUserSelectedResources([]);
    }
    else {
      setPdfChecked(true)
      let userSelectedResources // Container for user selected file references
      try {
        // userSelectedResources = [
        //     {
        //         "name": "62682650-2ca4-46f2-bbb5-7aa28c05f0b4_borrower_Truework---DEMO_employment-verification.pdf",
        //         "id": "5cdcc08e-cce6-44e1-a6e3-4cd821715a55",
        //         "mimeType": "application/pdf"
        //     }
        // ]
        //
        // userSelectedResources = [
        //     {
        //         "name": "62682650-2ca4-46f2-bbb5-7aa28c05f0b4_borrower_Truework---DEMO_employment-verification.pdf",
        //         "id": "4a16b51e-ef69-4893-9ed0-f6368b5d4067",
        //         "mimeType": "application/pdf"
        //     },
        //     {
        //         "name": "IRS+Form+W-2.pdf",
        //         "id": "c4533385-5b93-4162-b226-63ab3c037305",
        //         "mimeType": "application/pdf"
        //     }
        // ]
        const applicationObject = await host.getObject('application')
        userSelectedResources = await applicationObject.performAction('getAvailableResources')
        // console.log(userSelectedResources)
        setUserSelectedResources(userSelectedResources);
        let filename = "";
        if(userSelectedResources.length == 0) {
        }
        else if(userSelectedResources.length == 1){
          filename = userSelectedResources[0].name;
          if(filename.length > 38){
            filename = filename.substring(0, 30) + "..." + filename.substring(filename.length - 5);
          }
        }
        else{
          filename = "Multiple files";
        }
        setPdfFilename(filename);
        console.log(filename)

      } catch (error) {
        console.log("ERROR")
        console.log({error})
      }
    }
  }

  const selectedPartnerChange = async function(){
    var _selectedPartner = document.getElementById("partnersSelect").value;
    setSelectedPartner(_selectedPartner)
  }

  const partnersClicked = async function () {
    console.log("partners clicked");

    // await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Go to partners page", "", "/")

    history.push({
      pathname: "/partners",
      state: {
        domain,
        apiKey,
        verificationType
      },
    });
  };

  const transactionHistoryClicked = async function () {
    console.log("transaction history clicked");

    // await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Go to partners page", "", "/")

    history.push({
      pathname: "/transactions",
      state: {
        transactionRequests: [
          {
            transactionId: originationContext.transactionId,
          },
        ],
        domain,
        apiKey,
        verificationType,
      },
    });
  };

  function checkBorrowerEnabled(e, index) {
    if (isBorrowerEnabled[index] === true && e.target.checked === false) {
      borrowerEmployments[index].forEach((emp) => {
        emp.status = false;
      });
      setBorrowerEmployments([...borrowerEmployments]);
    }

    let newEnabled = [...isBorrowerEnabled];
    newEnabled[index] = e.target.checked;
    setIsBorrowerEnabled(newEnabled);
  }

  function checkCoborrowerEnabled(e, index) {
    if (isCoborrowerEnabled[index] === true && e.target.checked === false) {
      coborrowerEmployments[index].forEach((emp) => {
        emp.status = false;
      });
      setCoborrowerEmployments([...coborrowerEmployments]);
    }

    let newEnabled = [...isCoborrowerEnabled];
    newEnabled[index] = e.target.checked;
    setIsCoborrowerEnabled(newEnabled);
  }

  function BorrowerEmploymentCheckbox(index) {
    let borrowerEmployments = _.get(encompassOriginData,
        "response_payload.loan.applications["+index+"].borrower.employment",
        []
      ).map((emp) => ({
        ...emp,
        status: false,
      }));
    let filtered = borrowerEmployments
      .filter(
        (emp) =>
          emp.employerName !== undefined &&
          emp.employerName !== null &&
          emp.employerName.length > 0
      )

    if (filtered.length === 0) {
      return (<div className="simplelabel missing">Missing</div>)
    }
    return filtered.map((emp) => {
      return (
        <div
          key={`borrowerEmployment-${emp.id}`}
          className="verification-block-1-10"
          style={{ marginTop: "10px" }}
        >
          <div>
            <input
              type="checkbox"
              className="checkbox-employment"
              key={`cb-${emp.id}`}
              id={emp.id}
              onClick={(e) => handleBorrowerEmploymentCheckbox(e, emp.id, index)}
            />
          </div>
          <div className="verificationValueDiv">
            <span className="verificationValue">{emp.employerName}</span>
          </div>
        </div>
      );
    });
  };

  function CoborrowerEmploymentCheckbox(index) {
    let coborrowerEmployments = _.get(encompassOriginData,
        "response_payload.loan.applications["+index+"].coborrower.employment",
        []
      ).map((emp) => ({
        ...emp,
        status: false,
      }));
    let filtered = coborrowerEmployments
      .filter(
        (emp) =>
          emp.employerName !== undefined &&
          emp.employerName !== null &&
          emp.employerName.length > 0
      )

    if (filtered.length === 0) {
      return (<div className="simplelabel missing">Missing</div>)
    }

    return filtered.map((emp) => {
      return (
        <div
          key={`coborrowerEmployment-${emp.id}`}
          className="verification-block-1-10"
          style={{ marginTop: "10px" }}
        >
          <div>
            <input
              type="checkbox"
              className="checkbox-employment"
              key={`cb-${emp.id}`}
              id={emp.id}
              onClick={(e) => handleCoborrowerEmploymentCheckbox(e, emp.id, index)}
            />
          </div>
          <div className="verificationValueDiv">
            <span className="verificationValue">{emp.employerName}</span>
          </div>
        </div>
      );
    });
  };

  function handleBorrowerEmploymentCheckbox(e, id, index) {
    borrowerEmployments[index]
      .filter((emp) => emp.id === id)
      .forEach((emp) => (emp.status = e.target.checked));
    setBorrowerEmployments([...borrowerEmployments]);
    let allowVerification = false;
    for(let k in borrowerEmployments){
      for(let j in borrowerEmployments[k])
      if(borrowerEmployments[k][j].status === true){
        allowVerification = true;
      }
    }
    for(let k in coborrowerEmployments){
      for(let j in coborrowerEmployments[k])
      if(coborrowerEmployments[k][j].status === true){
        allowVerification = true;
      }
    }
    setVerificationButtonEnabled(allowVerification)
  }

  function handleCoborrowerEmploymentCheckbox(e, id, index) {
    coborrowerEmployments[index]
      .filter((emp) => emp.id === id)
      .forEach((emp) => (emp.status = e.target.checked));
    setCoborrowerEmployments([...coborrowerEmployments]);
    let allowVerification = false;
    for(let k in borrowerEmployments){
      for(let j in borrowerEmployments[k])
      if(borrowerEmployments[k][j].status === true){
        allowVerification = true;
      }
    }
    for(let k in coborrowerEmployments){
      for(let j in coborrowerEmployments[k])
      if(coborrowerEmployments[k][j].status === true){
        allowVerification = true;
      }
    }
    setVerificationButtonEnabled(allowVerification)
  }

  async function updateVerificationType(encompassOriginData) {
    const productName = _.get(
      encompassOriginData,
      "response_payload.product.name",
      ""
    )
      .replace(/\s/g, "")
      .toLowerCase();

    if (productName.startsWith("staircaseincome") || productName.startsWith("staircasevoi")) {
      setVerificationTypeLabel("Income Verification");
      verificationType = "income";
    } else if (productName.startsWith("staircaseasset") || productName.startsWith("staircasevoa")) {
      setVerificationTypeLabel("Asset Verification");
      verificationType = "asset";
    } else if (productName.startsWith("staircaseemployment") || productName.startsWith("staircasevoe")) {
      setVerificationTypeLabel("Employment Verification");
      verificationType = "employment";
    }
    console.log("productName", productName);
    console.log("verificationType", verificationType);
    console.log("verificationTypeLabel", verificationTypeLabel);
  }

  async function updatePartners(domain, apiKey){
      if (verificationType === "asset"){
        const asset_partners = [{"byoc":false,"active":true,"status":"active","partner":"finicity","details":{"description":"Finicity , https://www.finicity.com/"},"order":2,"partnerTitle":"Finicity","disabled":""},{"byoc":false,"active":true,"status":"active","partner":"plaid","details":{"description":"Plaid , https://plaid.com/"},"order":8,"partnerTitle":"Plaid","disabled":""}]
        setPartners(asset_partners)
        return
      }
      let partners = await getPartners(domain, apiKey, verificationType);
      for(let p in partners){
        partners[p]["partnerTitle"] =  partners[p]["partner"].charAt(0).toUpperCase() +  partners[p]["partner"] .slice(1)
        if(partners[p]["active"] !== true)
          partners[p]["disabled"] = "disabled"
        else
          partners[p]["disabled"] = ""
      }
      console.log(partners)
      setPartners(partners)
  }

  function checkMissingFields(encompassOriginData, index) {
    if (encompassOriginData && encompassOriginData.response_payload) {
      const missingFields = checkRequiredFields(
        encompassOriginData,
        isBorrowerEnabled,
        isCoborrowerEnabled,
          index
      );

      if (missingFields.length > 0) {
        return (
          <div id="missing-fields-container" className="borrower missingInfo">
            <div className="missing_info">
              Missing Information
            </div>
            <ul>
              {missingFields.map((field) => (
                <li key={field}>
                  <span>
                    <b>{field}</b>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        );
      }
      return null;
    }
  }

  function getBorrowerCompany(borrower){
    return _.get(
        borrower,
        "employment",
        []
    )
        .filter((emp) => emp.currentEmploymentIndicator === true && emp.employerName)
        .map(e => e.employerName)
        .join(", ")
  }

  function getBorrowerTitle(borrower){
    return _.get(
        borrower,
        "employment",
        []
    )
      .filter((emp) => emp.currentEmploymentIndicator === true)
      .reduce((prev, curr) => prev + ", " + curr.positionDescription, "")
      .substring(1)
  }

  function getBorrowerAddress(borrower){
    return _.get(
        borrower,
        "residences",
        []
    )
      .filter((res) => res.residencyType === "Current")
      .map(getAddressString)
      .filter((address) => address !== "")
      .join("\n")
  }

  function getAddressString(address){
    let addressString = "";
    if (address.urla2020StreetAddress) {
      addressString += address.urla2020StreetAddress + ", "
    }

    if (address.addressCity){
      addressString += address.addressCity
    }

    if (address.addressState){
      addressString += "(" + address.addressState + ")"
    }

    return addressString
  }

  const applications = _.get(encompassOriginData, "response_payload.loan.applications")
  
  console.log(typeof(applications))
  console.log(applications)
  console.log(encompassOriginData)
  console.log(typeof(encompassOriginData))

  return (
    <>
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div id="verificationContainer" className="pageContainer">
          <div className="verificationHeaderHolder">
            <p id="verificationHeader" className="verificationContentHeader">
              New {verificationTypeLabel}
            </p>
          </div>
          <div className="sectionContainer-start">
            <div className="partnerInfoHolder">
              <div className="labelHolder">
                <p className="selectLabel">Select your verification partner:</p>
              </div>
              <div className="partnerSelectHolder">
                <select
                    name="partnersSelect"
                    id="partnersSelect"
                    className="partnersSelect"
                    onChange={selectedPartnerChange}
                   >
                  {
                    verificationType !== 'asset' ? (
                    <option value="default">Waterfall</option>) : null
                  }

                  {
                    partners.filter(function(p) {
                      return !p["disabled"];
                    }).map((value, key) =>
                      <option className="partnersSelectOption"
                              value={value["partner"]}
                      >{value["partnerTitle"]}</option>
                    )
                  }
                </select>

                    <button onClick={partnersClicked} className="partnerInfoButton">
                      <div className="infoIconHolder">
                        <img src={InfoIcon} alt="help" className="infoIcon"/>
                      </div>
                    </button>

                <button onClick={partnersClicked} className="partnerManageButton">
                  <div className="partnerManageLabel">
                    Manage Partners
                  </div>
                </button>
                <button onClick={transactionHistoryClicked} className="transactionHistoryButton">
                  <div className="transactionHistoryLabel">
                    Transaction History
                  </div>
                </button>

              </div>
            </div>
              <div className="ReportSelectorArea">
                { selectedPartner == "truework" || selectedPartner == "default" ? (
                    <div id="pdfWindowContainer" className="ReportSelector">
                      <input
                          type="checkbox"
                          className="ReportSelectorInput"
                          id="fileSelect"
                          checked={pdfChecked}
                          onClick={(e) => openPdfSelectWindow(e)}
                      />
                      <div className="ReportSelectorText">Add borrower approval document:</div>
                      <div className="ReportSelectorText">{pdfFilename}</div>
                    </div>
                ) : null }
                <div className="ReportSelector">
                  <div className="ReportSelectorTitleText">Upload reports to Encompass:</div>
                </div>
                <div className="ReportSelector">
                  <input
                      type="checkbox"
                      className="ReportSelectorInput"
                      id="fileSelect"
                      checked={staircaseReportChecked}
                      onChange={(e) => setStaircaseReportChecked(e.target.checked)}
                  />
                  <div className="ReportSelectorText">Upload Staircase Report</div>
                </div>
                <div className="ReportSelector">
                  <input
                      type="checkbox"
                      className="ReportSelectorInput"
                      id="fileSelect"
                      checked={partnerReportChecked}
                      onChange={(e) => setPartnerReportChecked(e.target.checked)}
                  />
                  <div className="ReportSelectorText">Upload partner report (if available)</div>
                </div>
              </div>

            <div className="BorrowerInfoHolder">
            <p>Please select who you want to verify data from:</p>
            {applications.map((value, index) => {
              let input_id = `checkbox-borrower-${index}`;
              let input_id_co = `checkbox-coborrower-${index}`;
              return <div className="borrower_coborrower">
                <div className="borrower top">
                  <div className="borrowerCheckbox">
                    <input
                      type="checkbox"
                      className="checkbox-borrower"
                      id={input_id}
                      onClick={(e) => checkBorrowerEnabled(e, index)}
                    />
                  </div>
                  <div className="borrowerData">
                    <div className="borrowerName">
                       <div className="boldedLabel">Borrower: </div>
                      {
                        (value["borrower"]["firstName"].length > 0 && value["borrower"]["lastName"].length > 0) ?
                            (
                        <div className="simplelabel">{ value["borrower"]["firstName"]} {value["borrower"]["lastName"]}</div>
                          ):
                          (
                              <div className="simplelabel missing">Missing</div>
                          )
                      }
                    </div>
                    <div className="borrowerCompanyTitle">
                      <div className="borrowerCompany">
                        <div className="boldedLabel">Company: </div>
                        {
                        (getBorrowerCompany(value["borrower"]).length > 1) ?
                            (
                        <div className="simplelabel">{getBorrowerCompany(value["borrower"])}</div>
                          ):
                          (
                              <div className="simplelabel missing">Missing</div>
                          )
                      }
                      </div>
                      {/*<div className="borrowerTitle">*/}
                      {/*  <div className="boldedLabel title"><b>Title: </b></div>*/}
                      {/*  {*/}
                      {/*  (getBorrowerTitle(value["borrower"]).length > 1) ?*/}
                      {/*      (*/}
                      {/*  <div className="simplelabel">{getBorrowerTitle(value["borrower"])}</div>*/}
                      {/*    ):*/}
                      {/*    (*/}
                      {/*        <div className="simplelabel missing">Missing</div>*/}
                      {/*    )*/}
                      {/*}*/}
                      {/*</div>*/}
                    </div>

                    <div className="borrowerAddress">
                      <div className="boldedLabel">Address: </div>
                      {
                        (getBorrowerAddress(value["borrower"]).length > 1) ?
                            (
                        <div className="simplelabel">{getBorrowerAddress(value["borrower"])}</div>
                          ):
                          (
                              <div className="simplelabel missing">Missing</div>
                          )
                      }
                    </div>

                    {isBorrowerEnabled[index] ? (
                      <div className="borrowerBottom">
                        <div className="borrowerDivider">
                          <div className="borrowerDividerRectangle">
                          </div>
                        </div>
                        <div className="borrowerCheckboxes">
                          <div className="borrowerEmployer">
                            Employer:
                          </div>
                          <div className="borrowerEmployments">
                            {BorrowerEmploymentCheckbox(index)}
                          </div>
                        </div>

                      </div>
                    ) : null}
                </div>

                </div>

              <div className="borrower coborrower">

                {value["coborrower"]["firstName"] != null ?(


                  <div className="borrowerCheckbox">
                    <input
                      type="checkbox"
                      className="checkbox-borrower"
                      id={input_id}
                      onClick={(e) => checkCoborrowerEnabled(e, index)}
                    />
                  </div>
                    ):null}
                {value["coborrower"]["firstName"] != null ?(
                  <div className="borrowerData">
                     <div className="borrowerName">
                       <div className="boldedLabel">Co-Borrower: </div>
                      {
                        (value["coborrower"]["firstName"].length > 0 && value["coborrower"]["lastName"].length > 0) ?
                            (
                        <div className="simplelabel">{ value["coborrower"]["firstName"]} {value["coborrower"]["lastName"]}</div>
                          ):
                          (
                              <div className="simplelabel missing">Missing</div>
                          )
                      }
                    </div>
                    <div className="borrowerCompanyTitle">
                      <div className="borrowerCompany">
                        <div className="boldedLabel">Company: </div>
                        {
                        (getBorrowerCompany(value["coborrower"]).length > 1) ?
                            (
                        <div className="simplelabel">{getBorrowerCompany(value["coborrower"])}</div>
                          ):
                          (
                              <div className="simplelabel missing">Missing</div>
                          )
                      }
                      </div>
                      {/*<div className="borrowerTitle">*/}
                      {/*  <div className="boldedLabel title"><b>Title: </b></div>*/}
                      {/*  {*/}
                      {/*  (getBorrowerTitle(value["borrower"]).length > 1) ?*/}
                      {/*      (*/}
                      {/*  <div className="simplelabel">{getBorrowerTitle(value["coborrower"])}</div>*/}
                      {/*    ):*/}
                      {/*    (*/}
                      {/*        <div className="simplelabel missing">Missing</div>*/}
                      {/*    )*/}
                      {/*}*/}
                      {/*</div>*/}

                      <div className="borrowerCompanyTitle">
                    </div>
                    </div>

                    <div className="borrowerAddress">
                      <div className="boldedLabel">Address: </div>
                      {
                        (getBorrowerAddress(value["coborrower"]).length > 1) ?
                            (
                        <div className="simplelabel">{getBorrowerAddress(value["coborrower"])}</div>
                          ):
                          (
                              <div className="simplelabel missing">Missing</div>
                          )
                      }
                    </div>

                    {isCoborrowerEnabled[index] ? (
                      <div className="borrowerBottom">
                        <div className="borrowerDivider">
                          <div className="borrowerDividerRectangle">
                          </div>
                        </div>
                        <div className="borrowerCheckboxes">
                          <div className="borrowerEmployer">
                            Employer:
                          </div>
                          <div className="borrowerEmployments">
                            {CoborrowerEmploymentCheckbox(index)}
                          </div>
                        </div>

                      </div>
                    ) : null}
                  </div>
                    ):null}

                </div>

                {checkMissingFields(encompassOriginData, index)}
              </div>
            })}
            </div>

          </div>

          <div id="buttons_holder" className="sectionContainer-start">
            {/*<button*/}
            {/*  id="cancelButton"*/}
            {/*  onClick={() => closeInteraction(host)}*/}
            {/*  style={{ marginTop: "30px" }}*/}
            {/*>*/}
            {/*  Cancel*/}
            {/*</button>*/}
            <button
              id="verificationButton"
              onClick={verificationButtonClicked}
              disabled={!verificationButtonEnabled || verifButtonClicked}
            >
              Start Verification
              {verifButtonClicked === true ? (
                <Loader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={20}
                  width={20}
                  id="loader"
                  style={{
                    position: "relative",
                    left: "5%",
                  }}
                />
              ) : (
              <div className="nextArrowHolder">
                <img src={ArrowRight} className="nextArrowIcon"/>
              </div>
              )
              }
          </button>
          </div>
        </div>


      )}
    </>
  );
}

export default VerificationStartPage;

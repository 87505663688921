import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  clearAuthTokens,
  getLocalTokens,
  getUser,
  setTokens,
  signIn
} from 'api/auth';
import {
  LosAttributes,
  SignInParams,
  UserAttributes,
  UserInfo
} from 'api/auth/types';

import { CompanySetupStatus } from 'api/setup/types';

interface AuthContextProps {
  user: {
    firstName?: string;
    lastName?: string;
    email?: string;
    isApiKeySubmitted?: boolean;
    companyStatus?: CompanySetupStatus;
    companyName?: string;
    apiKey?: string;
    subDomain?: string;
  };
  losConfig: LosAttributes[];
  isLoaded: boolean;
}

interface IAuthUpdaterContext {
  (_: Partial<AuthContextProps>): void;
}

const AuthStateContext = createContext<Partial<AuthContextProps>>({});
const AuthUpdaterContext = createContext<IAuthUpdaterContext>(() => {});

function normalizeUserAttributes(userAttributes: UserAttributes) {
  return {
    firstName: userAttributes.first_name,
    lastName: userAttributes.last_name,
    email: userAttributes.email,
    isApiKeySubmitted: userAttributes.api_key_submitted === 'True',
    companyStatus: userAttributes.company_status,
    apiKey: userAttributes.api_key,
    companyName: userAttributes.company_name,
    subDomain: userAttributes.environment_fqdn
  };
}

function normalizeUserInfo(userInfo: UserInfo) {
  return {
    user: normalizeUserAttributes(userInfo.UserAttributes),
    losConfig: userInfo.LosAttributes
  };
}

export function AuthProvider(props: PropsWithChildren<{}>) {
  const [session, setSession] = useState<Partial<AuthContextProps>>({
    isLoaded: false
  });

  const { isLoaded } = session;

  const _setSession = useCallback(
    async ({ user, isLoaded, losConfig }: Partial<AuthContextProps>) => {
      setSession((currentSessionData) => {
        const newSessionData = {
          ...currentSessionData,
          user: {
            ...currentSessionData.user,
            ...user
          },
          losConfig
        };

        if (isLoaded) {
          newSessionData.isLoaded = isLoaded;
        }
        return newSessionData;
      });
    },
    []
  );

  useEffect(() => {
    let isCancelledRef = { value: false };
    if (isLoaded) return;

    const fetchUser = async () => {
      const userInfo = await getUser();
      if (userInfo) {
        await _setSession(normalizeUserInfo(userInfo));
      }
    };

    const { accessToken, refreshToken } = getLocalTokens();
    if (accessToken && refreshToken) {
      fetchUser()
        .then(() => {
          if (!isCancelledRef.value) {
            setSession((s) => ({ ...s, isLoaded: true }));
          }
        })
        .catch((err) => {
          setSession({ isLoaded: true });
          console.log(err);
        });
    } else {
      if (!isCancelledRef.value) {
        setSession((s) => ({ ...s, isLoaded: true }));
      }
    }

    return () => {
      isCancelledRef.value = true;
    };
  }, [_setSession, isLoaded]);

  if (!isLoaded)
    return <LinearProgress aria-label="loading auth data" color="primary" />;
  return (
    <AuthStateContext.Provider value={session}>
      <AuthUpdaterContext.Provider value={_setSession}>
        {props.children}
      </AuthUpdaterContext.Provider>
    </AuthStateContext.Provider>
  );
}

const missingProviderError = (hook: string) =>
  new Error(`${hook} must be used within a AuthProvider`);

export function useAuthState() {
  const authState = useContext(AuthStateContext);
  if (typeof authState === 'undefined') {
    throw missingProviderError('useAuthState');
  }
  return authState;
}

export function useAuthUpdater() {
  const setAuth = useContext(AuthUpdaterContext);
  if (typeof setAuth === 'undefined') {
    throw missingProviderError('useAuthUpdater');
  }
  return useCallback(
    (authData: Partial<AuthContextProps>) => setAuth(authData),
    [setAuth]
  );
}

export function useSignOut() {
  const setAuth = useContext(AuthUpdaterContext);
  if (typeof setAuth === 'undefined') {
    throw missingProviderError('useSignIn');
  }

  return useCallback(() => {
    clearAuthTokens();
    setAuth({
      user: undefined,
      losConfig: undefined,
      isLoaded: false
    });
  }, [setAuth]);
}

export function useSignIn() {
  const setAuth = useContext(AuthUpdaterContext);
  if (typeof setAuth === 'undefined') {
    throw missingProviderError('useSignIn');
  }

  return useCallback(
    async ({ email, password }: SignInParams) => {
      const {
        authentication_results: { refresh_token, id_token, token_type }
      } = await signIn({ email, password });

      setTokens(`${token_type} ${id_token}`, refresh_token);

      const userInfo = await getUser();
      if (userInfo) {
        setAuth(normalizeUserInfo(userInfo));
      }
    },
    [setAuth]
  );
}

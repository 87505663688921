import startCase from 'lodash/startCase';
import { Partner } from 'api/partners/types';
import { PartnersProducts } from 'api/partners';

const standardizeLabel = (label: string) => {
  return startCase(label.replace(/-/g, ' '));
};

export function getTabA11yProps(name: PartnersProducts) {
  return {
    id: `partners-tab-${name}`,
    label: standardizeLabel(name),
    'aria-controls': `partners-tabpanel-${name}`
  };
}

export const normalizeRawPartners = (partners: Partner[]) => {
  return partners.map(({ has_credentials, ...data }) => {
    return {
      ...data,
      hasCredentials: has_credentials
    };
  });
};

export function sortByOrderAndStatus(p1: Partner, p2: Partner) {
  if (!p1.active && !p2.active) return p1.order - p2.order;
  if (!p1.active) return 1;
  if (!p2.active) return -1;
  return p1.order - p2.order;
}

export function sortByOrder(p1: Partner, p2: Partner) {
  return p1.order - p2.order;
}

export function isFirstActivePartner(partners: Partner[], partner: string) {
  const activePartners = [...partners]
    .sort(sortByOrder)
    .filter((p) => p.active);
  return activePartners.findIndex((p) => p.partner === partner) === 0;
}

export function isLastActivePartner(partners: Partner[], partner: string) {
  const activePartners = [...partners]
    .sort(sortByOrder)
    .filter((p) => p.active);
  return (
    activePartners.findIndex((p) => p.partner === partner) ===
    activePartners.length - 1
  );
}

export function fixPartnersOrdering(partners: readonly Partner[]) {
  return partners.map((partner: Readonly<Partner>, index) => {
    return {
      ...partner,
      order: index + 1
    };
  });
}

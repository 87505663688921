import { ChangeEvent, useCallback, useState } from 'react';

export function useTab(initialTab = 0) {
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const handleTabChange = useCallback(
    (event: ChangeEvent<{}>, newValue: number) => {
      setSelectedTab(newValue);
    },
    []
  );

  return {
    selectedTab,
    setSelectedTab,
    handleTabChange
  };
}

export function triggerOnResolve<
  TTriggeredFn extends (...args: any) => unknown
>(triggeredFn: TTriggeredFn) {
  return function <TPromiseFn extends (...args: any) => Promise<unknown>>(
    promiseFn: TPromiseFn
  ) {
    return function (...args: Parameters<TPromiseFn>) {
      return promiseFn(...args).then((...promiseReturn) => {
        triggeredFn();
        return Promise.resolve(...promiseReturn);
      });
    };
  };
}

import { array } from "yup";

const allTransactions = [];

async function createTransaction(domain, apiKey, verificationType, requestData) {
    try {
        let executionId = null
        let verificationUrl = null

        if (verificationType === 'income') {
            verificationUrl = "los-byte-VOI"
        }
        else if (verificationType === 'emplyoment') {
            verificationUrl = "los-byte-VOE"
        }
        else if (verificationType === 'asset') {
            verificationUrl = "los-byte-VOA"
        }
        else {
            return executionId
        }

        console.log("https://" + domain + "/job/jobs/" + verificationUrl + "/executions/")

        executionId = await fetch(
            "https://" + domain + "/job/jobs/" + verificationUrl + "/executions/",
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": apiKey
                },
                body: JSON.stringify(requestData),
            }
        );

        return await executionId.json();
    } catch (error) {
        console.log(error);
    }
}

async function getVerificationTransactions(domain, apiKey, loanIdentifier, verificationType, verifications) {

    let incomeVerifications = []

    await verifications["items"].forEach(async verification => {

        let verificationData = await getIncomeData(verification.transaction_id, domain, apiKey, null)
        if (verificationData != undefined) {
            incomeVerifications.push(verificationData)
        }


    });

}

async function getHistoryTransactions(domain, apiKey, loanIdentifier, indexNextPage, verificationType, previousTransactionId, previousCollectionId) {
    try {
        let url
        let res
        if (indexNextPage.length > 0) {
            res = await fetch(indexNextPage, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": apiKey,
                }
            });
        }
        else {
            res = await fetch("https://" + domain + "/persistence/indexes/loan_id_index_jmespath/items?value=" + loanIdentifier, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": apiKey,
                }
            });
        }

        if (res.ok) {
            const getHistoryTransactionsResponse = await res.json()
            return getHistoryTransactionsResponse
        }
        else if (res.status === 403) {
            console.error(
                "Your Staircase credentials are not valid. Please contact your admin."
            );
            return []
        } else {
            console.error("Error occured.", res);
            return []
        }
    } catch (error) {
        console.log(error);
        return []
    }
}

async function getTransactionCollectionData(transactions, verificationType, previousTransactionId, previousCollectionId, domain, apiKey, indexNextPage, loanIdentifier) {

    let tempTransId = previousTransactionId
    let tempColleId = previousCollectionId

    transactions.forEach(async transaction => {

        if (tempTransId != transaction.transaction_id && tempColleId != transaction.collection_id) {
            tempTransId = transaction.transaction_id
            tempColleId = transaction.collection_id
            await getData(transaction.transaction_id, verificationType, previousTransactionId, previousCollectionId, domain, apiKey, indexNextPage)
        }
    });

    console.log(allTransactions)
    if (allTransactions.length < 10) {
        console.log("call again")
        await getHistoryTransactions(domain, apiKey, loanIdentifier, indexNextPage, verificationType, tempTransId, tempColleId)
    }
}

async function getData(transaction_id, verificationType, previousTransactionId, previousCollectionId, domain, apiKey, indexNextPage) {
    if (verificationType == "income") {
        let incomeResult = await getIncomeData(transaction_id, domain, apiKey, indexNextPage)
    }
    else if (verificationType == "employment") {
        let employmentResult = await getEmploymentData(transaction_id, domain, apiKey, indexNextPage)
    }
}

async function getIncomeData(transaction_id, domain, apiKey, indexNextPage) {
    try {
        const res = await fetch(
            "https://" +
            domain +
            "/persistence/transactions/" + transaction_id + "/collections",
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": apiKey,
                }
            }
        );
        if (res.ok) {
            const data = await res.json();
            data.forEach((trans) => {
                if (trans["metadata"]["product_name"] == "income") {
                    let tempData = {
                        "referenceId": trans["transaction_id"] + "_" + trans["collection_id"],
                        "created": trans["metadata"]["created_at"],
                        "product_name": "Staircase Income Verification",
                        "status": trans["metadata"]["invocation_status"],
                        "fail_reason": trans["metadata"]["failure_reason"],
                    }
                    allTransactions.push(tempData)
                    //setTransactions(allTransactions => [...allTransactions, tempData])
                    return tempData
                }
            });

        }
        else {
            return false
        }
    }
    catch (e) {
        console.log(e)
        return false
    }
}

async function getEmploymentData(transaction_id, domain, apiKey, indexNextPage) {
    try {
        const res = await fetch(
            "https://" +
            domain +
            "/persistence/transactions/" + transaction_id + "/collections",
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": apiKey,
                }
            }
        );
        if (res.ok) {
            const data = await res.json();
            data.forEach((trans) => {
                if (trans["metadata"]["product_name"] == "employment") {
                    let tempData = {
                        "referenceId": trans["transaction_id"] + "_" + trans["collection_id"],
                        "created": trans["metadata"]["created_at"],
                        "product_name": "Staircase Employment Verification",
                        "status": trans["metadata"]["invocation_status"],
                        "fail_reason": trans["metadata"]["failure_reason"],
                    }
                    allTransactions.push(tempData)
                    //setTransactions(allTransactions => [...allTransactions, tempData])
                    return true
                }
            });
        }
        else {
            return false
        }
    }
    catch (e) {
        console.log(e)
        return false
    }
}

export { createTransaction, getHistoryTransactions };
import React, {useEffect, useState} from "react";
import _ from "lodash";
import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {createTransaction, initializeOriginationContext,} from "../service/encompassService";
import {checkRequiredFieldsForAsset} from "../util/requiredFieldsForAsset";
import {create_health_metric} from "../service/healthService";
import { getPartners } from "../service/staircaseService";
import ArrowRight from "../images/icons/arrow_right.png";


import "../App.css";
import {useHistory} from "react-router-dom";
let verificationType = "asset"

async function closeInteraction(host) {
  const transactionObject = await host.getObject("transaction");
  transactionObject.close();
}

function AssetVerificationStartPage({ data }) {
  let transactionRequests = [];

  const borrowerLanguage = "encompass-to-staircase-borrower-mapping",
    coborrowerLanguage = "encompass-to-staircase-co-borrower-mapping",
    history = useHistory(),
    { host } = data;

  const [encompassOriginResult, setEncompassOriginResult] = useState([]);
  const [originationContext, setOriginationContext] = useState([]);
  const [transactionObject, setTransactionObject] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [verifButtonClicked, setVerifButtonClicked] = useState(false);
  const [verificationButtonEnabled, setVerificationButtonEnabled] = useState(false);
  const [isBorrowerEnabled, setIsBorrowerEnabled] = useState([]);
  const [isCoborrowerEnabled, setIsCoborrowerEnabled] = useState([]);
  const [partners, setPartners] = useState([]);
  const [verificationTypeLabel, setVerificationTypeLabel] = useState("Asset Verification");
  // const [verificationType, setVerificationType] = "income"


  useEffect(() => {
    const initVerification = async () => {
      let initResult;
      initResult = await initializeOriginationContext(host);
      setEncompassOriginResult(initResult.encompassOriginResult);
      setOriginationContext(initResult.originationContext);
      setTransactionObject(initResult.transactionObject);
      const domain = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "response_payload.credentials.domain",
        ""
      );
      const apiKey = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "response_payload.credentials.api_key",
        ""
      );
      let transaction_id = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "encompassOriginData.transaction_id",
        ""
      );
      let apps = _.get(initResult.encompassOriginResult, "encompassOriginData.response_payload.loan.applications")
      let borEmployments = [];
      let coBorEmployments = [];
      let borrowerEnabledStart = [];
      for(let app_index in apps){
        borrowerEnabledStart[app_index] = false
      }
      setIsBorrowerEnabled(borrowerEnabledStart);
      setIsCoborrowerEnabled(borrowerEnabledStart);
      updateVerificationType(initResult.encompassOriginResult.encompassOriginData,);
      updatePartners(domain, apiKey);
      setLoading(false);
      await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Opened the Encompass UI page, page successfully loaded", transaction_id, "/")
    };
    initVerification().catch((error)=> {
      history.push({
          pathname: "/error",
          state: {
            error:error.message,
            domain: domain,
            apiKey: apiKey
          },
        });
    });
  }, []);


  let { encompassOriginData } = encompassOriginResult;


  const domain = _.get(
    encompassOriginData,
    "response_payload.credentials.domain",
    ""
  );
  const apiKey = _.get(
    encompassOriginData,
    "response_payload.credentials.api_key",
    ""
  );

  if (originationContext && originationContext.transactionId) {
    history.push({
      pathname: "/asset/result/single",
      state: {
        transactionRequests: [
          {
            transactionId: originationContext.transactionId,
          },
        ],
        domain,
        apiKey,
        verificationType,
      },
    });
  }

  const transactionHistoryClicked = async function () {
    console.log("transaction history clicked");

    // await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Go to partners page", "", "/")

    history.push({
      pathname: "/transactions",
      state: {
        transactionRequests: [
          {
            transactionId: originationContext.transactionId,
          },
        ],
        domain,
        apiKey,
        verificationType,
      },
    });
  };

  const verificationButtonClicked = async function () {
    setVerifButtonClicked(true);
    var selectedPartner = document.getElementById("partnersSelect").value;
    console.log(`Creating ${verificationType}`);
    console.log("isBorrowerEnabled", isBorrowerEnabled);
    console.log(encompassOriginResult);


    const promiseArray = [];

    for(let index in isBorrowerEnabled) {
      if (isBorrowerEnabled[index]) {
        let requestObject = {
          request: {
            type: verificationTypeLabel,
            options: {
              languageType: borrowerLanguage,
              loanIndex: index,
              borrowerType: "borrower",
              partner_name: selectedPartner,
            },
          },
        };
        console.log(requestObject)
        promiseArray.push(createTransaction(requestObject, transactionObject, encompassOriginResult));
        await new Promise((r) => setTimeout(r, 200));
      }
    }

    for(let index in isCoborrowerEnabled) {
      if (isCoborrowerEnabled[index]) {
        let requestObject = {
          request: {
            type: verificationTypeLabel,
            options: {
              languageType: coborrowerLanguage,
              loanIndex: index,
              borrowerType: "coborrower",
              partner_name: selectedPartner,
            },
          },
        };
        console.log(requestObject)
        promiseArray.push(createTransaction(requestObject, transactionObject, encompassOriginResult));
        await new Promise((r) => setTimeout(r, 200));
      }
    }

    transactionRequests = await Promise.all(promiseArray);
    console.log(transactionRequests)
    await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Ordered the verification", "", "/")

    if (transactionRequests.length > 1) {
      history.push({
        pathname: "/result",
        state: {
          transactionRequests,
          domain,
          apiKey,
          verificationType,
        },
      });
    } else {
      history.push({
        pathname: "/result",
        state: {
          transactionRequests,
          domain,
          apiKey,
          verificationType,
        },
      });
    }
  };

  const partnersClicked = async function () {
    console.log("partners clicked");

    // await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Go to partners page", "", "/")

    history.push({
      pathname: "/partners",
      state: {
        domain,
        apiKey,
        verificationType
      },
    });
  };

  function checkBorrowerEnabled(e, index) {
    let newEnabled = [...isBorrowerEnabled];
    newEnabled[index] = e.target.checked;
    setIsBorrowerEnabled(newEnabled);
    for(let enabled in newEnabled){
        if(newEnabled[enabled]){
            setVerificationButtonEnabled(true)
            return
        }
      }

    for(let enabled in isCoborrowerEnabled){
        if(isCoborrowerEnabled[enabled]){
            setVerificationButtonEnabled(true)
            return
        }
      }
    setVerificationButtonEnabled(false)
  }

  function checkCoborrowerEnabled(e, index) {
    let newEnabled = [...isCoborrowerEnabled];
    newEnabled[index] = e.target.checked;
    setIsCoborrowerEnabled(newEnabled);
    for(let enabled in newEnabled){
        if(newEnabled[enabled]){
            setVerificationButtonEnabled(true)
            return
        }
      }

    for(let enabled in isBorrowerEnabled){
        if(isBorrowerEnabled[enabled]){
            setVerificationButtonEnabled(true)
            return
        }
      }
    setVerificationButtonEnabled(false)

  }


  async function updateVerificationType(encompassOriginData) {
    const productName = _.get(
      encompassOriginData,
      "response_payload.product.name",
      ""
    )
      .replace(/\s/g, "")
      .toLowerCase();

    if (productName.startsWith("staircaseincome") || productName.startsWith("staircasevoi")) {
      setVerificationTypeLabel("Income Verification");
      verificationType = "income";
    } else if (productName.startsWith("staircaseasset") || productName.startsWith("staircasevoa")) {
      setVerificationTypeLabel("Asset Verification");
      verificationType = "asset";
    } else if (productName.startsWith("staircaseemployment") || productName.startsWith("staircasevoe")) {
      setVerificationTypeLabel("Employment Verification");
      verificationType = "employment";
    }
    console.log("productName", productName);
    console.log("verificationType", verificationType);
    console.log("verificationTypeLabel", verificationTypeLabel);
  }

  async function updatePartners(domain, apiKey){
      if (verificationType === "asset"){
        const asset_partners = [{"byoc":false,"active":true,"status":"active","partner":"finicity","details":{"description":"Finicity , https://www.finicity.com/"},"order":2,"partnerTitle":"Finicity","disabled":""},{"byoc":false,"active":true,"status":"active","partner":"plaid","details":{"description":"Plaid , https://plaid.com/"},"order":8,"partnerTitle":"Plaid","disabled":""}]
        setPartners(asset_partners)
        return
      }
      let partners = await getPartners(domain, apiKey, verificationType);
      for(let p in partners){
        partners[p]["partnerTitle"] =  partners[p]["partner"].charAt(0).toUpperCase() +  partners[p]["partner"] .slice(1)
        if(partners[p]["active"] !== true)
          partners[p]["disabled"] = "disabled"
        else
          partners[p]["disabled"] = ""
      }
      console.log(partners)
      setPartners(partners)
  }

  function checkMissingFields(encompassOriginData, index) {
    if (encompassOriginData && encompassOriginData.response_payload) {
      const missingFields = checkRequiredFieldsForAsset(
        encompassOriginData,
        isBorrowerEnabled,
        isCoborrowerEnabled, 
          index
      );

      if (missingFields.length > 0) {
        return (
          <div id="missing-fields-container" className="borrower missingInfo">
            <div className="missing_info">
              Missing Information
            </div>
            <ul>
              {missingFields.map((field) => (
                <li key={field}>
                  <span>
                    <b>{field}</b>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        );
      }
      return null;
    }
  }

  function getBorrowerCompany(borrower){
    return _.get(
        borrower,
        "employment",
        []
    )
        .filter((emp) => emp.currentEmploymentIndicator === true)
        .reduce((prev, curr) => prev + ", " + curr.employerName, "")
        .substring(1)
  }

  const applications = _.get(encompassOriginData, "response_payload.loan.applications")

  return (
    <>
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div id="verificationContainer" className="pageContainer">
          <div className="verificationHeaderHolder">
            <p id="verificationHeader" className="verificationContentHeader">
              New {verificationTypeLabel}
            </p>
          </div>
          <div className="sectionContainer-start">

           <div className="partnerInfoHolder">
              <div className="labelHolder">
                <p className="selectLabel">Select your verification partner:</p>
              </div>
              <div className="partnerSelectHolder">
                <select
                    name="partnersSelect"
                    id="partnersSelect"
                    className="partnersSelect"
                   >
                  {
                    verificationType !== 'asset' ? (
                    <option value="default">Waterfall</option>) : null
                  }

                  {
                    partners.filter(function(p) {
                      return !p["disabled"];
                    }).map((value, key) =>
                      <option className="partnersSelectOption"
                              value={value["partner"]}
                      >{value["partnerTitle"]}</option>
                    )
                  }
                </select>
                <button onClick={transactionHistoryClicked} className="transactionHistoryButton">
                  <div className="transactionHistoryLabel">
                    Transaction History
                  </div>
                </button>
              </div>
            </div>


            <div className="BorrowerInfoHolder">
            <p>Please select who you want to verify data from:</p>
            {applications.map((value, index) => {
              let input_id = `checkbox-borrower-${index}`;
              let input_id_co = `checkbox-coborrower-${index}`;
              return <div className="borrower_coborrower">
                  <div className="borrower top">
                  <div>
                    <div className="verification-row-1-20">
                      <div className="verification-block-1-1">
                        <input
                          type="checkbox"
                          className="checkbox-borrower"
                          id={input_id}
                          onClick={(e) => checkBorrowerEnabled(e, index)}
                        />
                      </div>
                      <div className="borrowerName">
                         <div className="boldedLabel">Borrower: </div>
                        {
                          (value["borrower"]["firstName"].length > 0 && value["borrower"]["lastName"].length > 0) ?
                              (
                          <div className="simplelabel">{ value["borrower"]["firstName"]} {value["borrower"]["lastName"]}</div>
                            ):
                            (
                                <div className="simplelabel missing">Missing</div>
                            )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {value["coborrower"]["firstName"] != null ?(
              <div id="coborowwerVerificationContainer" className="borrower coborrower">
                {(value["coborrower"]["firstName"] || value["coborrower"]["lastName"]) ? (
                <div>
                  <div className="verification-row-1-20">
                    <div className="verification-block-1-1">
                      <input
                        type="checkbox"
                        className="checkbox-coborrower"
                        id={input_id_co}
                        onClick={(e) => checkCoborrowerEnabled(e, index)}
                      />
                    </div>
                    <div className="borrowerName">
                       <div className="boldedLabel">Co-Borrower: </div>
                      {
                        (value["coborrower"]["firstName"].length > 0 && value["coborrower"]["lastName"].length > 0) ?
                            (
                        <div className="simplelabel">{ value["coborrower"]["firstName"]} {value["coborrower"]["lastName"]}</div>
                          ):
                          (
                              <div className="simplelabel missing">Missing</div>
                          )
                      }
                    </div>
                  </div>

                </div>
                ) : null}
              </div>) : null}
                
                {checkMissingFields(encompassOriginData, index)}
            </div>
            })}

          </div>
          </div>

          <div id="buttons_holder" className="sectionContainer-start">
            <button
              id="verificationButton"
              onClick={verificationButtonClicked}
              disabled={!verificationButtonEnabled || verifButtonClicked}
            >
              Start Verification
              {verifButtonClicked === true ? (
                <Loader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={20}
                  width={20}
                  id="loader"
                  style={{
                    position: "relative",
                    left: "5%",
                  }}
                />
              ) : (
              <div className="nextArrowHolder">
                <img src={ArrowRight} className="nextArrowIcon"/>
              </div>
              )}
          </button>
          </div>
        </div>
      )}
    </>
  );
}

export default AssetVerificationStartPage;

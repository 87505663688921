import styled from 'styled-components';

import { BP, CONTENT_SPACING, CONTENT_WIDTHS } from 'layout/constants';

type ContentContainerProps = {
  size?: 'lg' | 'md';
};

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: ${({ size = 'lg' }: ContentContainerProps) =>
    CONTENT_WIDTHS[size] + 'px'};
  padding: 0 ${CONTENT_SPACING.xs}px;
  width: 100%;

  @media (min-width: ${BP.sm}px) {
    padding: 0 ${CONTENT_SPACING.sm}px;
  }

  @media (min-width: ${BP.md}px) {
    padding: 0 ${CONTENT_SPACING.md}px;
  }

  @media (min-width: ${BP.lg}px) {
    box-sizing: content-box;
    padding: 0 ${CONTENT_SPACING.lg}px;
    width: calc(100% - 400px);
  }

  @media (min-width: ${BP.xl}px) {
    padding: 0 ${CONTENT_SPACING.xl}px;
  }
`;

export default ContentContainer;

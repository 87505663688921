import { PropsWithChildren } from 'react';
import ContentGrid from 'layout/components/ContentGrid';

interface TabPanelProps {
  index: number;
  value: number;
}

export function TabPanel(props: PropsWithChildren<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  if (value === index) {
    return (
      <ContentGrid
        id={`partners-tabpanel-${index}`}
        aria-labelledby={`partners-tab--${index}`}
        {...other}
      >
        {children}
      </ContentGrid>
    );
  }

  return null;
}

import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import {
  Button as BaseButton,
  ButtonSpinner,
  ButtonLabel
} from 'shared/ui/base-button';

export interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
}

export default function Button({
  children,
  isLoading,
  color = 'primary',
  disabled,
  ...buttonProps
}: ButtonProps) {
  return (
    <BaseButton
      {...buttonProps}
      disableElevation
      disableRipple
      color={color}
      isLoading={isLoading}
      disabled={isLoading || disabled}
    >
      {isLoading && <ButtonSpinner size={15} color={'inherit'} />}
      <ButtonLabel>{children}</ButtonLabel>
    </BaseButton>
  );
}

import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import api from 'api/axios';
import {
  AuthResponse,
  EmailAvailabilityResponse,
  SignUpParams,
  SignInParams,
  UserInfo
} from './types';

export const refreshToken = ({ refreshToken }: { refreshToken: string }) => {
  const options: any = { skipAuthRefresh: true };
  return api
    .post(`refresh_token`, { refresh_token: refreshToken }, options)
    .then(({ data }: AxiosResponse<AuthResponse>) => data);
};

export const signUp = (data: SignUpParams) => {
  return api.post('setupAccount', data);
};

export const signIn = ({ email: username, password }: SignInParams) => {
  return api
    .post('sign_in', { username, password })
    .then(({ data }: AxiosResponse<AuthResponse>) => data);
};

let cancelToken: CancelTokenSource;
export const checkEmailAvailability = (email: string) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = axios.CancelToken.source();
  return api
    .get(`check_email_availability/${email}`, {
      cancelToken: cancelToken.token
    })
    .then(({ data }: { data: EmailAvailabilityResponse }) => data);
};

export const clearAuthTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const setTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

export const getLocalTokens = () => {
  return {
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken')
  };
};

export const refreshLocalToken = async () => {
  const _refreshToken = getLocalTokens().refreshToken;
  if (_refreshToken) {
    const {
      authentication_results: { refresh_token, id_token, token_type }
    } = await refreshToken({
      refreshToken: _refreshToken
    });
    const newToken = `${token_type} ${id_token}`;
    setTokens(newToken, refresh_token);
  }
};

export const getUser = () => {
  return api.get('/user-info').then(({ data }: { data: UserInfo }) => data);
};

export const sendForgotPasswordEmail = (email: string) => {
  return api.post(`/forgot_password`, { username: email });
};

import React, { useState, useEffect } from "react";
import {useHistory, useLocation} from "react-router-dom";
import _ from "lodash";
import Loader from "react-loader-spinner";
import {
  getTransactionInfo,
  downloadReport,
  getStaircaseCollection,
} from "../service/staircaseService";
import { getAmericanDateFromDate, toUpperFirstChar } from "../util/helper";
import useInterval from "../hooks/useInterval";

function SingleVerificationResultPage({ data }) {
  console.log("SingleVerificationResultPage");
  const location = useLocation();
  const history = useHistory();
  // var  domain = "russell-dev.staircaseapi.com";
  // var  apiKey = "73884068-3362-4899-b7db-4c72e0ac46b2";
  // var  verificationType = "employment";
  // var  transactionRequests = [{transactionId: "fe6dde4e-6a28-4512-8cd2-7ed978a0329d"}];
  var { transactionRequests, domain, apiKey, verificationType } = location.state;
  const [transactionsResult, setTransactionsResult] = useState({});
  const [collectionResponse, setCollectionResponse] = useState({});
  const [verifButtonClicked, setVerifButtonClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [borrowerType, setBorrowerType] = useState("borrower");
  const [loanIndex, setLoanIndex] = useState(0);
  const [status, setStatus] = useState("pending");
  console.log("VerificationType", verificationType)
  if(verificationType === "employment")
    verificationType = "Employment Verification";
  else if(verificationType === "income")
    verificationType = "Income Verification";
  console.log("VerificationTypeAfter", verificationType)

  useEffect(() => {
    const initPending = async () => {
      const tr = transactionRequests[0];
      const res = await getTransactionInfo(tr.transactionId, domain, apiKey);

      const referenceNumber = _.get(
        res,
        "response_payload.response.referenceNumber",
        null
      );
      if (referenceNumber) {
        await getRefrenceResult(res, referenceNumber);
      }

      setBorrowerType(_.get(res, "response_payload.request.options.borrowerType"));
      setLoanIndex(parseInt(_.get(res, "response_payload.request.options.loanIndex", 0)));
      setTransactionsResult({ ...res });
      setLoading(false);
    };
    initPending();
  }, []);

  async function getRefrenceResult(trResult, referenceNumber) {
    const refArr = referenceNumber.split("#");
    const staircaseTransactionId = refArr[0];
    const staircaseCollectionId = refArr[1];

    const colRes = await getStaircaseCollection(
      domain,
      apiKey,
      staircaseTransactionId,
      staircaseCollectionId
    );
    setTransactionsResult({ ...trResult });
    setCollectionResponse({ ...colRes });
    setStatus("completed");
  }

  const transactionHistoryClicked = async function () {
    console.log("transaction history clicked");

    history.push({
      pathname: "/transactions",
      state: {
        transactionRequests,
        domain,
        apiKey,
        verificationType,
      },
    });
  };

  function getStatusColor() {
    const state = _.get(
      collectionResponse,
      "metadata.verification_state",
      null
    );
    console.log("getStatusColor", state);
    if (state === "PENDING") {
      return "#5E84FF";
    } else if (state === "COMPLETED") {
      return "#1E9C0A";
    } else if (state === "FAILED") {
      return "#D5320F";
    } else {
      return "#5E84FF";
    }
  }

  useInterval(async () => {
    try {
      if (
        transactionsResult.response_payload &&
        transactionsResult.response_payload.status === "pending"
      ) {
        const trRes = await getTransactionInfo(
          transactionsResult.response_payload.id,
          domain,
          apiKey
        );
        if (trRes.response_payload.status !== "pending") {
          const referenceNumber = _.get(
            trRes,
            "response_payload.response.referenceNumber",
            null
          );
          if (referenceNumber) {
            await getRefrenceResult(trRes, referenceNumber);
          }
        }
      }
    } catch (e) {
      console.log("Error in polling transaction", e);
    }
  }, 10000);

  function getFirstName() {
    return _.get(
      transactionsResult,
      `response_payload.request.loan.applications[${loanIndex}].${borrowerType}.firstName`,
      ""
    );
  }

  function getLastName() {
    return _.get(
      transactionsResult,
      `response_payload.request.loan.applications[${loanIndex}].${borrowerType}.lastName`,
      ""
    );
  }

  function getCompany() {
    return _.get(
      transactionsResult,
      `response_payload.request.loan.applications[${loanIndex}].${borrowerType}.employment`,
      []
    )
      .filter((emp) => emp.currentEmploymentIndicator === true)
      .reduce((prev, curr) => prev + ", " + curr.employerName, "")
      .substring(1);
  }

  function getTitle() {
    return _.get(
      transactionsResult,
      `response_payload.request.loan.applications[${loanIndex}].${borrowerType}.employment`,
      []
    )
      .filter((emp) => emp.currentEmploymentIndicator === true)
      .reduce((prev, curr) => prev + ", " + curr.positionDescription, "")
      .substring(1);
  }

  function showEmploymentVerificationInfo(collectionResponse) {
    const employmentResponseData = collectionResponse.data;
    const employmentResponseMetaData = collectionResponse.metadata;

    // status === "Failed"
    //   ? (document.getElementById("verificationStatus").style.color = "#D5320F")
    //   : (document.getElementById("verificationStatus").style.color = "#1E9C0A");
  }

  function showIncomeVerificationInfo(collectionResponse) {
    const incomeResponseData = collectionResponse.data;
    const incomeResponseMetaData = collectionResponse.metadata;

    // status === "Failed"
    //   ? (document.getElementById("verificationStatus").style.color = "#D5320F")
    //   : (document.getElementById("verificationStatus").style.color = "#1E9C0A");
  }

  function addSalaryPeriods(incomeItems) {
    const currentYear = new Date().getFullYear();
    const income_map = {};
    income_map[currentYear] = {};
    income_map[currentYear - 1] = {};
    income_map[currentYear - 2] = {};

    if(Array.isArray(incomeItems)) {
      incomeItems.forEach((item) => {
        if (item.other_period_description > currentYear - 3)
          income_map[item.other_period_description][item.type] =
              item.other_period_total;
      });
    }
    return (
      <>
        {addSalaryPeriodsHelper(income_map[currentYear], currentYear)}
        {addSalaryPeriodsHelper(income_map[currentYear - 1], currentYear - 1)}
        {addSalaryPeriodsHelper(income_map[currentYear - 2], currentYear - 2)}
      </>
    );
  }

  function addSalaryPeriodsHelper(item, year) {
    return (
      <div class="verificationResultContent" style={{ marginTop: "12px" }}>
        <div class="verification-row" style={{ marginTop: "0px" }}>
          <div class="verification-block-1-1">
            <p
              style={{ fontSize: "18px", height: "30px" }}
              class="verificationContentHeader"
            >
              {year} Income
            </p>
          </div>
        </div>
        <div class="divider"></div>

        <div class="verification-row-1-1-1">
          <div class="verification-block-1-1">
            <span class="verificationLabel">Gross Salary:</span>
            <span class="verificationValue">
              {_.get(item, "Gross", "-").toString().substring(0, 14)}
            </span>
          </div>
          <div class="verification-block-1-1">
            <span class="verificationLabel">Net Salary:</span>
            <span class="verificationValue">
              {_.get(item, "Net", "-").toString().substring(0, 14)}
            </span>
          </div>
          <div class="verification-block-1-1">
            <span class="verificationLabel">Overtime:</span>
            <span class="verificationValue">
              {_.get(item, "Overtime", "-").toString().substring(0, 14)}
            </span>
          </div>
        </div>

        <div class="verification-row-1-1-1">
          <div class="verification-block-1-1">
            <span class="verificationLabel">Commission:</span>
            <span class="verificationValue">
              {_.get(item, "Comission", "-").toString().substring(0, 14)}
            </span>
          </div>
          <div class="verification-block-2-3">
            <span class="verificationLabel">Bonus:</span>
            <span class="verificationValue">
              {_.get(item, "Bonus", "-").toString().substring(0, 14)}
            </span>
          </div>
          <div class="verification-block-2-3">
            <span class="verificationLabel">Other:</span>
            <span class="verificationValue">
              {_.get(item, "Other", "-").toString().substring(0, 14)}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div
          id="verification-result-container"
          className="pageContainer"
          style={{ marginBottom: "200px" }}
        >

          <div className="verificationHeaderHolder">
            <p id="verificationHeader" className="verificationContentHeader">
              New {verificationType}
            </p>
          </div>
          <div className="sectionContainer">
            <div className="verificationHeaderContainer">
              <p className="verificationContentHeader"><b>Borrower Verification</b></p>
              <button onClick={transactionHistoryClicked} className="transactionHistoryButtonInResult">
                <div className="transactionHistoryLabel">
                  Transaction History
                </div>
              </button>
            </div>

            <div className="verificationResultContent">
              <div className="verification-row">
                <div className="verification-block-2-5">
                  <div>
                    <span className="verificationLabel">First Name:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span className="verificationValue" id="firstName">
                      {getFirstName()}
                    </span>
                  </div>
                </div>
                <div className="verification-block-2-5">
                  <div>
                    <span className="verificationLabel">Last Name:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span className="verificationValue" id="lastName">
                      {getLastName()}
                    </span>
                  </div>
                </div>
              </div>

              <div className="verification-row" id="employmentCompanyInfo">
                <div className="verification-block-2-5">
                  <div>
                    <span className="verificationLabel">Company:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span className="verificationValue" id="companyName">
                      {getCompany()}
                    </span>
                  </div>
                </div>
                <div className="verification-block-2-5">
                  <div>
                    <span className="verificationLabel">Title:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span className="verificationValue" id="titleName">
                      {getTitle()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sectionContainer verification">

            <div className="verificationResultContent">
              <div className="verification-row">
                <div className="verification-block-1-5">
                  <div>
                    <span className="verificationLabel">Type:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span className="verificationValue" id="verificationType">
                      {verificationType}
                    </span>
                  </div>
                </div>
                <div className="verification-block-1-1"></div>
              </div>

              <div className="verification-row">
                <div className="verification-block-1-2">
                  <div>
                    <span className="verificationLabel">Request Date:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span
                      className="verificationValue"
                      id="verificationRequestDate"
                    >
                      {getAmericanDateFromDate(
                        _.get(
                          collectionResponse,
                          "metadata.created_at",
                          Date().toString()
                        )
                      )}
                    </span>
                  </div>
                </div>
                <div className="verification-block-1-4">
                  <div>
                    <span className="verificationLabel">Status:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span
                      className="verificationValue"
                      id="verificationStatus"
                      style={{
                        color: getStatusColor(
                          _.get(
                            collectionResponse,
                            "metadata.verification_state",
                            "pending"
                          )
                        ),
                      }}
                    >
                      {toUpperFirstChar(
                        _.get(
                          collectionResponse,
                          "metadata.verification_state",
                          "pending"
                        )
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {verificationType === "Employment Verification" &&
          status !== "pending" ? (
            <div className="sectionContainer" id="employment-container">
              <div className="verificationHeaderContainer">
                <p className="verificationContentHeader">
                   <b>Employment Information </b>
                </p>
                <button
                  disabled={verifButtonClicked}
                  onClick={async () => {
                    setVerifButtonClicked(true);
                    await downloadReport(
                      _.get(transactionsResult, "response_payload.id", ""),
                      domain,
                      apiKey
                    );
                    setVerifButtonClicked(false);
                  }}
                  className="downloadButton"
                >
                  Download
                  {verifButtonClicked === true ? (
                    <Loader
                      type="TailSpin"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      id="loader"
                      style={{
                        position: "relative",
                        left: "5%",
                      }}
                    />
                  ) : null}
                </button>
              </div>

              <div className="verificationResultContent">
                <div className="verification-row"></div>

                <div className="verification-row-1-1-1">
                  <div className="verification-block-1-3">
                    <div>
                      <span className="verificationLabel">Status:</span>
                    </div>
                    <div className="verificationValueDiv">
                      <span className="verificationValue" id="employmentStatus">
                        {_.get(
                          collectionResponse,
                          "data.deal_sets.deal_set[0].deals.deal[0].parties.party[0].roles.role[0].borrower.employers.employer[0].employment.status_type",
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="verification-block-2-3">
                    <div>
                      <span className="verificationLabel">Start Date:</span>
                    </div>
                    <div className="verificationValueDiv">
                      <span className="verificationValue" id="startDate">
                        {_.get(
                          collectionResponse,
                          "data.deal_sets.deal_set[0].deals.deal[0].parties.party[0].roles.role[0].borrower.employers.employer[0].employment.start_date",
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="verification-block-2-3">
                    <div>
                      <span className="verificationLabel">End Date:</span>
                    </div>
                    <div className="verificationValueDiv">
                      <span className="verificationValue" id="endDate">
                        {_.get(
                          collectionResponse,
                          "data.deal_sets.deal_set[0].deals.deal[0].parties.party[0].roles.role[0].borrower.employers.employer[0].employment.end_date",
                          "-"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "14px" }}>
                  <div className="verification-block-1-3">
                    <div>
                      <span className="verificationLabel">
                        Position Description:
                      </span>
                    </div>
                    <div className="verificationValueDiv">
                      <span
                        className="verificationValue"
                        id="positionDescription"
                      >
                        {_.get(
                          collectionResponse,
                          "data.deal_sets.deal_set[0].deals.deal[0].parties.party[0].roles.role[0].borrower.employers.employer[0].employment.position_description",
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {verificationType === "Income Verification" &&
          status !== "pending" ? (
            <div className="sectionContainer" id="income-container">
              <div className="verificationHeaderContainer">
                <p className="verificationContentHeader">
                   <b>Income Information </b></p>
                <button
                  disabled={verifButtonClicked}
                  onClick={async () => {
                    setVerifButtonClicked(true);
                    await downloadReport(
                      _.get(transactionsResult, "response_payload.id", ""),
                      domain,
                      apiKey
                    );
                    setVerifButtonClicked(false);
                  }}
                  className="downloadButton"
                >
                  Download
                  {verifButtonClicked === true ? (
                    <Loader
                      type="TailSpin"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      id="loader"
                      style={{
                        position: "relative",
                        left: "5%",
                      }}
                    />
                  ) : null}
                </button>
              </div>

              <div id="salaryPeriods">
                {addSalaryPeriods(
                  _.get(
                    collectionResponse,
                    "data.deal_sets.deal_set[0].deals.deal[0].parties.party[0].roles.role[0].borrower.current_income.current_income_items.current_income_item",
                    ""
                  )
                )}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default SingleVerificationResultPage;

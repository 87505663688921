import styled from 'styled-components';

import { BP, GUTTERS, CONTENT_SPACING } from 'layout/constants';

const ContentStack = styled.div`
  padding: 0 0 ${CONTENT_SPACING.xs}px 0;

  @media (min-width: ${BP.sm}px) {
    padding: 0 0 ${CONTENT_SPACING.sm}px 0;
  }

  @media (min-width: ${BP.md}px) {
    padding: 0 0 ${GUTTERS.md}px 0;
  }

  @media (min-width: ${BP.lg}px) {
    padding: 0 0 ${GUTTERS.lg}px 0;
  }

  @media (min-width: ${BP.xl}px) {
    padding: 0 0 ${GUTTERS.xl}px 0;
  }
`;

export default ContentStack;

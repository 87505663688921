import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import Loader from "react-loader-spinner";

import { getHistoryTransactions } from "./byteServices";
import { getVerifications } from "./byteServices";

import {
    getLoanTransactions,
    getTransactionInfo,
    downloadReport,
    getStaircaseCollection,
} from "../../service/staircaseService";

import { useHistory } from "react-router-dom";

function TransactionListPage() {

    const location = useLocation();
    const history = useHistory()

    var { customerName, verificationType, loanIdentifier, domain, apiKey } = location.state;

    const [entityId, setEntityId] = useState("");
    const [loading, setLoading] = useState(true);
    const [transactionsResult, setTransactionsResult] = useState([]);
    const [allTransactions, setTransactions] = useState([]);
    const [detailsResult, setDetailsResult] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [indexNextPage, setIndexNextPage] = useState([]);
    const [nextTokenPresent, setNextTokenPresent] = useState(false);

    const [previousTransactionId, setPreviousTransactionId] = useState("");
    const [previousCollectionId, setPreviousCollection] = useState("");

    const [numberOfShownVerifications, setNumberOfShownVerifications] = useState(0);

    let counter = 0
    let listOfVerifications = []

    useEffect(() => {

        const initPending = async () => {

            console.log("Init start.")

            const historyTransactions = await getHistoryTransactions(domain, apiKey, loanIdentifier, indexNextPage, verificationType, previousTransactionId, previousCollectionId);
            try {
                const transactions = historyTransactions["items"]
                const tempIndexNextPage = historyTransactions["_links"]["next"]
                const tempNextToken = historyTransactions["next_token"]
                if (tempNextToken != null) {
                    setNextTokenPresent(true)
                    setIndexNextPage(tempIndexNextPage)
                    setHasMore(true);
                }
                else {
                    setNextTokenPresent(false)
                    setIndexNextPage([])
                    setHasMore(false);
                }

                await getTransactionCollectionData(transactions)

                if (counter < 5 && tempNextToken != null) {
                    console.log("Get more transactions.")
                    await getMoreTransactions(domain, apiKey, loanIdentifier, tempIndexNextPage)
                }
                else {
                    counter = 0
                }

                setLoading(false);

                console.log("Init end.")
            }
            catch (e) {
                console.log(e)
                setIndexNextPage([])
                setHasMore(false);
                setLoading(false);
            }

        };
        initPending();
    }, []);

    function getStatusColor(state) {
        console.log("getStatusColor", state);
        if (state === "completed") {
            return "#1E9C0A";
        } else if (state === "failed") {
            return "#D5320F";
        } else {
            return "#5E84FF";
        }
    }

    async function getTransactionCollectionData(transactions) {

        console.log("Step 1")

        let tempTransId = previousTransactionId
        let tempColleId = previousCollectionId

        transactions.forEach(async transaction => {

            if (tempTransId != transaction.transaction_id && tempColleId != transaction.collection_id) {
                setPreviousTransactionId(transaction.transaction_id)
                setPreviousCollection(transaction.collection_id)
                tempTransId = transaction.transaction_id
                tempColleId = transaction.collection_id
                await getData(transaction.transaction_id)
            }
        });

        return true
    }

    async function getData(transaction_id) {
        console.log("Step 2")
        if (verificationType == "income") {
            let incomeResult = await getIncomeData(transaction_id)
        }
        else if (verificationType == "employment") {
            let employmentResult = await getEmploymentData(transaction_id)
        }
    }

    async function getIncomeData(transaction_id) {
        console.log("Step 3")
        try {
            const res = await fetch(
                "https://" +
                domain +
                "/persistence/transactions/" + transaction_id + "/collections?limit=50",
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": apiKey,
                    }
                }
            );
            if (res.ok) {
                const data = await res.json();
                data.forEach((trans) => {
                    if (trans["metadata"]["product_name"] == "income") {
                        let tempData = {
                            "referenceId": trans["transaction_id"] + "_" + trans["collection_id"],
                            "created": trans["metadata"]["created_at"],
                            "product_name": "Staircase Income Verification",
                            "status": trans["metadata"]["invocation_status"],
                            "fail_reason": trans["metadata"]["failure_reason"],
                        }

                        let tempReferenceId = tempData["referenceId"]
                        let existsFlag = false

                        listOfVerifications.forEach((verification) => {
                            if (verification == tempReferenceId) {
                                existsFlag = true
                                return
                            }
                        });

                        if (existsFlag) {
                            console.log("True")
                        }
                        else {
                            setTransactions(allTransactions => [...allTransactions, tempData])
                            listOfVerifications.push(tempData["referenceId"])
                            counter = counter + 1
                            setNumberOfShownVerifications(numberOfShownVerifications + 1)
                        }
                    }
                });

            }
            else {
                return false
            }
        }
        catch (e) {
            console.log(e)
            return false
        }
    }

    async function getEmploymentData(transaction_id) {
        console.log("Step 4")
        try {
            const res = await fetch(
                "https://" +
                domain +
                "/persistence/transactions/" + transaction_id + "/collections",
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": apiKey,
                    }
                }
            );
            if (res.ok) {
                const data = await res.json();
                data.forEach((trans) => {
                    if (trans["metadata"]["product_name"] == "employment") {
                        let tempData = {
                            "referenceId": trans["transaction_id"] + "_" + trans["collection_id"],
                            "created": trans["metadata"]["created_at"],
                            "product_name": "Staircase Employment Verification",
                            "status": trans["metadata"]["invocation_status"],
                            "fail_reason": trans["metadata"]["failure_reason"],
                        }
                        setTransactions(allTransactions => [...allTransactions, tempData])
                        return true
                    }
                });
            }
            else {
                return false
            }
        }
        catch (e) {
            console.log(e)
            return false
        }
    }

    const backButtonClicked = async function () {

        history.push({
            pathname: "/",
            state: {
                customerName,
                verificationType,
                loanIdentifier,
                domain,
                apiKey,
            },
        });
    };

    async function loadMoreButtonClicked() {
        const nextPage = currentPage + 1
        await loadTransactions(entityId, nextPage)
    }

    async function getMoreTransactions(domain, apiKey, loanIdentifier, indexNextPage) {
        const historyTransactions = await getHistoryTransactions(domain, apiKey, loanIdentifier, indexNextPage);
        try {
            const transactions = historyTransactions["items"]
            const tempIndexNextPage = historyTransactions["_links"]["next"]
            const tempNextToken = historyTransactions["next_token"]
            if (tempNextToken != null) {
                setNextTokenPresent(true)
                setIndexNextPage(tempIndexNextPage)
                setHasMore(true);
            }
            else {
                setNextTokenPresent(false)
                setIndexNextPage([])
                setHasMore(false);
            }

            let status = await getTransactionCollectionData(transactions)

            if (counter < 5 && tempNextToken != null) {
                console.log("Get more transactions function.")
                await getMoreTransactions(domain, apiKey, loanIdentifier, tempIndexNextPage)
            }
            else {
                counter = 0
            }

            setLoading(false);
        }
        catch (e) {
            console.log(e)
            setIndexNextPage([])
            setHasMore(false);
            setLoading(false);
        }
    }

    async function loadMoreButtonClickedd() {

        const historyTransactions = await getHistoryTransactions(domain, apiKey, loanIdentifier, indexNextPage);
        try {
            const transactions = historyTransactions["items"]
            const tempIndexNextPage = historyTransactions["_links"]["next"]
            const tempNextToken = historyTransactions["next_token"]
            if (tempNextToken != null) {
                setNextTokenPresent(true)
                setIndexNextPage(tempIndexNextPage)
                setHasMore(true);
            }
            else {
                setNextTokenPresent(false)
                setIndexNextPage([])
                setHasMore(false);
            }

            await getTransactionCollectionData(transactions)

            if (counter < 5 && tempNextToken != null) {
                console.log("Get more transactions function.")
                setLoading(true);
                await getMoreTransactions(domain, apiKey, loanIdentifier, tempIndexNextPage)
            }
            else {
                counter = 0
            }

            setLoading(false);
        }
        catch (e) {
            console.log(e)
            setIndexNextPage([])
            setHasMore(false);
            setLoading(false);
        }

    }

    async function loadTransactions(entity_id, page) {
        setLoadingMore(true);
        await setCurrentPage(page)
        const res = await getLoanTransactions(domain, apiKey, entity_id, 10, page * 10);

        const transactions = _.get(
            res,
            "response_payload",
            null
        );

        const details = await Promise.all(
            transactions.map((tr) => {
                return getDetails(tr);
            })
        );

        const more = transactions.length % 10 === 0;
        const cTransactions = transactionsResult;
        const cDetails = detailsResult;
        setDetailsResult([...cDetails, ...details]);
        setTransactionsResult([...cTransactions, ...transactions]);
        setHasMore(more);
        setLoadingMore(false);
    }

    async function getDetails(transaction) {
        if (transaction.status === "completed") {
            let trRes = await getTransactionInfo(
                transaction.id,
                domain,
                apiKey
            );
            return trRes
        } else {
            return { "response_payload": { "response": { "status": "processing" } } }
        }
    }

    return (
        <>
            {loading === true ? (
                <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={120}
                    width={120}
                    id="loader"
                    style={{
                        position: "absolute",
                        left: "46%",
                        top: "35%",
                        height: "31px",
                        width: "31px",
                    }}
                />
            ) : (
                <div
                    id="verification-result-container"
                    className="pageContainer"
                    style={{ marginBottom: "200px" }}
                >

                    <div className="verificationHeaderHolder">
                        <p id="verificationHeader" className="verificationContentHeader">
                            Byte Transaction History
                        </p>
                    </div>

                    <div className="sectionContainer verification">
                        <div id="topLeftDiv">
                            <p className="backText" onClick={backButtonClicked}>
                                &#5176; Back
                            </p>
                        </div>

                        {
                            allTransactions.map((item, index) => (
                                <div className="transactionListItem">
                                    <div className="verification-row-2-6-1">
                                        <span className="transactionListItemText">
                                            {item.created.split("T")[0]}
                                        </span>
                                        {<span className="transactionListItemText">
                                            {item.product_name.replace("Test", "")}
                                        </span>}
                                        <span className="transactionStatusText" style={{
                                            color: getStatusColor(
                                                item.status.toLowerCase()
                                            ),
                                        }}>
                                            {item.status.toUpperCase() ?? ""}
                                        </span>
                                    </div>
                                    {
                                        item.fail_reason ? (
                                            <div className="verification-row-1">
                                                <span className="transactionListItemFailText" style={{
                                                    color: getStatusColor(
                                                        item.status.toLowerCase()
                                                    ),
                                                }}>
                                                    Message: {item.fail_reason}
                                                </span>
                                            </div>
                                        ) :
                                            (<div />)
                                    }
                                </div>
                            ))
                        }
                        {hasMore && !loadingMore ? (<button
                            id="loadMoreButton"
                            onClick={loadMoreButtonClickedd}
                        >Load More</button>) : (<div />)}
                        {loadingMore === true ? (
                            <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={24}
                                width={24}
                                id="loader"
                                style={{
                                    position: "static",
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}
                            />
                        ) : (<div />)}
                    </div>
                </div>
            )}
        </>
    );
}

export default TransactionListPage;
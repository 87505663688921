import { MouseEventHandler, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import {
  Modal,
  ModalButtons,
  ModalContent,
  ModalProps,
  ModalTitle
} from 'shared/ui/modal';
import Button from 'shared/ui/button';
import TextField from 'shared/ui/textfield';
import { isAxiosError } from 'shared/utils/errors';

interface RemovePartnerCredentialsModalProps extends ModalProps {
  partner: string;
  onRemove: (partner: string, password: string) => Promise<unknown>;
}

export function RemovePartnerCredentialsModal({
  open,
  onClose,
  onRemove,
  partner
}: RemovePartnerCredentialsModalProps) {
  const closeModal: MouseEventHandler<HTMLButtonElement> = (e) =>
    onClose?.(e, 'backdropClick');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isValidating },
    setError,
    reset
  } = useForm({
    defaultValues: {
      password: ''
    },
    mode: 'all'
  });

  useEffect(() => {
    if (open) {
      return reset;
    }
  }, [reset, open]);

  const isConfirmButtonDisabled = isSubmitting || isValidating || !isValid;

  const submitForm = useCallback(
    ({ password }) => {
      return onRemove(partner, password)
        .then(() => {
          onClose?.({}, 'backdropClick');
        })
        .catch((err: unknown) => {
          let message = isAxiosError(err)
            ? err.response?.data?.message
            : 'An unexpected error has occurred';
          if (message === 'Not found') message = 'Wrong password';
          setError('password', { message, type: 'manual' });
        })
        .finally(() => {});
    },
    [partner, setError, onRemove, onClose]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={isSubmitting}
      disableBackdropClick={isSubmitting}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle onClose={isSubmitting ? undefined : onClose}>
          Remove Partner?
        </ModalTitle>
        <ModalContent>
          <Typography variant="body2" style={{ paddingBottom: 26 }}>
            To remove a partner you must enter the password you set when added
            your credentials.
          </Typography>
          <TextField
            fullWidth
            label={'Password'}
            type="password"
            autoComplete="false"
            inputProps={{
              ...register('password', {
                required: 'Required'
              })
            }}
            placeholder="Enter your credentials password"
            error={!!errors.password?.message}
            helperText={errors.password?.message}
          />
        </ModalContent>

        <ModalButtons justifyContent="flex-end">
          <Button onClick={closeModal} disabled={isSubmitting}>
            Keep Credentials
          </Button>
          <Button
            color="secondary"
            type="submit"
            variant="contained"
            disabled={isConfirmButtonDisabled}
            isLoading={isSubmitting}
          >
            Remove Credentials
          </Button>
        </ModalButtons>
      </form>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";

async function closeInteraction(host) {
  const transactionObject = await host.getObject("transaction");
  transactionObject.close();
}

function VerificationStarted({ data }) {
  const [loading, setLoading] = useState(true);

  const { host } = data;
  useEffect(() => {
    const initPending = async () => {
      setLoading(false);
    };
    initPending();
  }, []);

  return (
    <>
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div
          id="verification-result-container"
          className="pageContainer result"
          style={{ marginBottom: "200px" }}
        >
          <div className="sectionContainer">
            <div className="verificationHeaderContainer">
              <p className="verificationContentHeaderStartedPage">Verification has been started, you will see the status change on Encompass page, once it's finished.</p>
              <button
              id="verificationButton"
              onClick={() => closeInteraction(host)}
              style={{ marginTop: "30px" }}
            >
              Back to Encompass
            </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VerificationStarted;

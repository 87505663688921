import "./../../App.css";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";

import Header from "./Header"
import Verification from "./Verification"
import PartnersPage from "./../../pages/partners/index.js";
import Transactions from "./Transactions"
import MissingParameters from "./MissingParameters.js";

const Byte = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    let customerName;
    let verificationType;
    let loanIdentifier;
    let domain;
    let apiKey;

    try {
        customerName = queryParams.get("customerName").toLowerCase()
        verificationType = queryParams.get("verificationType").toLowerCase()
        loanIdentifier = queryParams.get("loanIdentifier").toLowerCase()
        domain = queryParams.get("domain").toLowerCase()
        apiKey = queryParams.get("apiKey").toLowerCase()
    } catch (error) {
        try {
           var { tempCustomerName, tempVerificationType, tempLoanIdentifier, tempDomain, tempApiKey } = location.state;
           customerName = tempCustomerName
           verificationType = tempVerificationType
           loanIdentifier = tempLoanIdentifier
           domain = tempDomain
           apiKey = tempApiKey
        }
        catch {
            console.log("Error: One of the required parameters is missing.")
            return (<div className="App">Missing verification parameters.</div>)
        }
    }

    console.log("Parameters: ", customerName, verificationType, domain, apiKey)

    return (
        <Router>
            <div className="App">
                <Header params={{ customerName: customerName }} />
                <Switch>
                    <Route exact path="/">
                        <Verification params={{ customerName: customerName, verificationType: verificationType, loanIdentifier: loanIdentifier, domain: domain, apiKey: apiKey }}/>
                    </Route>
                    <Route path="/partners">
                        <PartnersPage params={{ customerName: customerName, verificationType: verificationType, loanIdentifier: loanIdentifier, domain: domain, apiKey: apiKey }}/>
                    </Route>
                    <Route path="/transactions">
                        <Transactions params={{ customerName: customerName, verificationType: verificationType, loanIdentifier: loanIdentifier, domain: domain, apiKey: apiKey }}/>
                    </Route>
                    <Route path="/missingParameters">
                        <MissingParameters />
                    </Route>
                </Switch>
            </div>
        </Router>)
}

export default Byte;
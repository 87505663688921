import { ChangeEvent, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import {
  Card,
  CardSubTitle,
  CardTitle,
  PartnerCard,
  PartnerName,
  DeleteButton,
  LoadingSpinner,
  PartnerCredentialCheckIcon,
  PartnersListContainer,
  StaircaseContractLabel
} from 'pages/partners/components/ui';
import { TabPanel } from 'pages/partners/components/TabPanel';
import { AboutCard } from 'pages/partners/components/AboutCard';
import { PartnerIndex } from 'pages/partners/components/PartnerIndex';
import { RemovePartnerCredentialsModal } from 'pages/partners/components/RemovePartnerCredentialsModal';

import { MessageLabelToast } from 'features/alerts/components/MessageLabelToast';
import { useToast } from 'features/alerts/hooks/useToast';
import { ReorderButtons } from 'shared/components/reorder-buttons';
import { Partner, PartnerCredentials } from 'api/partners/types';
import Toggle from 'shared/ui/toggle';
import Button from 'shared/ui/button';

export interface ProductPartnersTabPanelProps {
  selectedIndex: number;
  index: number;
  location: object;
  isLoading: boolean;
  productListSubTitle: string;
  productAboutDescription: string;
  onMovePartner?: (from: number, to: number) => Promise<unknown>;
  onUpdatePartnerStatus?: (
    partner: string,
    status: boolean
  ) => Promise<unknown>;
  onDeletePartner?: (partner: string) => Promise<unknown>;
  onAddPartner?: (partner: Partner) => Promise<unknown>;
  onAddPartnerCredential?: (
    credentials: PartnerCredentials
  ) => Promise<unknown>;
  onRemovePartnerCredentials?: (
    partner: string,
    password: string
  ) => Promise<unknown>;
  productPartners: Partner[];
  partnerWebhookUrl?: string;
}

export function ProductPartnersTabPanel({
  index,
  isLoading,
  productListSubTitle,
  selectedIndex,
  onDeletePartner,
  onAddPartner,
  productPartners,
  productAboutDescription,
  onUpdatePartnerStatus,
  onMovePartner,
  onAddPartnerCredential,
  onRemovePartnerCredentials,
  partnerWebhookUrl
}: ProductPartnersTabPanelProps) {
  const [
    selectedPartnerToDeleteCredentials,
    setSelectedPartnerToDeleteCredentials
  ] = useState('');

  const { isShown: isSavedLabelShown, toastOnResolve } = useToast();

  const toastOnMovePartner = onMovePartner && toastOnResolve(onMovePartner);
  const toastOnAddPartnerCredential =
    onAddPartnerCredential && toastOnResolve(onAddPartnerCredential);

  const toastOnUpdatePartnerStatus =
    onUpdatePartnerStatus && toastOnResolve(onUpdatePartnerStatus);

  const toastOnRemovePartnerCredentials =
    onRemovePartnerCredentials && toastOnResolve(onRemovePartnerCredentials);

  return (
    <TabPanel value={selectedIndex} index={index}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <AboutCard
            subTitle={productAboutDescription}
            partners={productPartners}
            onAddPartnerCredential={toastOnAddPartnerCredential}
            webhookUrl={partnerWebhookUrl}
          />

          <Card>
            <PartnersListContainer>
              <Typography variant="body1">Your Partners</Typography>
              {isSavedLabelShown && <MessageLabelToast message="Saved!" />}
            </PartnersListContainer>

            {/* <CardSubTitle>{productListSubTitle}</CardSubTitle> */}
            {productPartners.map((p) => (
              <PartnerCard key={p.partner}>
                {onMovePartner && (
                  <ReorderButtons
                    onMoveUp={() => toastOnMovePartner?.(p.order, p.order - 1)}
                    onMoveDown={() =>
                      toastOnMovePartner?.(p.order, p.order + 1)
                    }
                    disableMoveUp={!onMovePartner || p.order === 1}
                    disableMoveDown={
                      !onMovePartner || p.order === productPartners.length
                    }
                  />
                )}

                {p.order > 0 && (
                  <PartnerIndex index={p.order} disabled={!p.active} />
                )}

                <PartnerName disabled={!p.active}>
                  {p.partner}
                  {p.hasCredentials && <PartnerCredentialCheckIcon />}
                </PartnerName>

                {p.hasCredentials ? (
                  onRemovePartnerCredentials && (
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setSelectedPartnerToDeleteCredentials(p.partner);
                      }}
                    >
                      Remove Contract
                    </Button>
                  )
                ) : (
                  <StaircaseContractLabel>
                    Staircase’s Contract
                  </StaircaseContractLabel>
                )}
                {onUpdatePartnerStatus && (
                  <Toggle
                    checked={p.active}
                    label={''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      toastOnUpdatePartnerStatus?.(p.partner, e.target.checked)
                    }
                  />
                )}

                {onDeletePartner && (
                  <DeleteButton onClick={() => onDeletePartner(p.partner)} />
                )}
              </PartnerCard>
            ))}
          </Card>
          {toastOnRemovePartnerCredentials && (
            <RemovePartnerCredentialsModal
              open={!!selectedPartnerToDeleteCredentials}
              onClose={() => {
                setSelectedPartnerToDeleteCredentials('');
              }}
              partner={selectedPartnerToDeleteCredentials || ''}
              onRemove={toastOnRemovePartnerCredentials}
            />
          )}
          {onAddPartner && (
            <Card>
              <CardTitle>Add your Partner Contract</CardTitle>
              <CardSubTitle>
                Some partners require you to enter your contract information.
                Partners added using your credentials will be identified with
                next to their names.
              </CardSubTitle>
            </Card>
          )}
        </>
      )}
    </TabPanel>
  );
}

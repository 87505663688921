import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useState, MouseEvent, useCallback } from 'react';

interface Action {
  label: string;
  callback: () => void;
}

interface MenuProps {
  actions: Action[];
}

export default function Menu({ actions }: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleItemClick = useCallback(
    (callback) => () => {
      handleClose();
      callback();
    },
    [handleClose]
  );

  return (
    <>
      <IconButton
        style={{ marginRight: '12px' }}
        edge="start"
        color="inherit"
        aria-label="menu"
        aria-controls="header-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <MuiMenu
        id="header-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map(({ callback, label }) => (
          <MenuItem key={label} onClick={handleItemClick(callback)}>
            {label}
          </MenuItem>
        ))}
      </MuiMenu>
    </>
  );
}

import { useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { Modal, ModalContent, ModalProps, ModalTitle } from 'shared/ui/modal';
import Button from 'shared/ui/button';
import { WebhookUrl, WebhookUrlInfoContainer } from './ui';

interface ProductUrlModalProps extends ModalProps {
  url: string;
}

export function ProductUrlModal({ url, open, onClose }: ProductUrlModalProps) {
  const copyUrlToClipBoard = useCallback(() => {
    navigator.clipboard.writeText(url);
  }, [url]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <ModalTitle onClose={onClose}>Product URL</ModalTitle>

      <ModalContent>
        <Typography variant="body2" style={{ paddingBottom: 10 }}>
          Please, add this URL to Partner’s Dashboard:
        </Typography>

        <WebhookUrlInfoContainer>
          <WebhookUrl>{url}</WebhookUrl>
          <Button onClick={copyUrlToClipBoard} variant="outlined">
            Copy
          </Button>
        </WebhookUrlInfoContainer>
      </ModalContent>
    </Modal>
  );
}

import api from 'api/axios';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { GetPartnersResponse, Partner, PartnerCredentials } from './types';

export enum PartnersProducts {
  EMPLOYMENT = 'employment',
  INCOME = 'income',
  ASSETS = 'assets',
  DOCUMENT_CLASSIFICATION = 'document-classification',
  DATA_EXTRACTION = 'data-extraction'
}
const BASE_URL = 'partners';

export const getPartners = async (product: PartnersProducts, domain, api_key) => {
  let url = "https://" + domain;
  let headers =  {
    'Content-Type': 'application/json',
    "x-api-key": api_key
  }
  return axios.create({
    baseURL: url,
    headers: headers
  })
  .get(`${product}/partners/`)
  .then(({ data }: AxiosResponse<GetPartnersResponse>) => data);
};

export const getPartnersOrdering = (product: PartnersProducts, domain, api_key) => {
  let url = "https://" + domain;
  let headers =  {
    'Content-Type': 'application/json',
    "x-api-key": api_key
  }
  return axios.create({
    baseURL: url,
    headers: headers
  })
  .get(`${product}/partners/ordering`)
  .then(({ data }: AxiosResponse<GetPartnersResponse>) => data);
};

export const updatePartnerStatus = (
  partner: string,
  active: boolean,
  product: PartnersProducts,
  domain,
  api_key
) => {
  let url = "https://" + domain;
  let headers =  {
    'Content-Type': 'application/json',
    "x-api-key": api_key
  }
  return axios.create({
    baseURL: url,
    headers: headers
  })
  .put(`${product}/partners/${partner}/status`, { active })
  .then(({ data }: AxiosResponse<GetPartnersResponse>) => data);
};

export const updatePartnersOrdering = (
  ordering: Partner[],
  product: PartnersProducts,
  domain,
  api_key
) => {
  let url = "https://" + domain;
  let headers =  {
    'Content-Type': 'application/json',
    "x-api-key": api_key
  }
  return axios.create({
    baseURL: url,
    headers: headers
  })
  .put(`${product}/partners/ordering`, { ordering });
};

export const addPartnerCredential = (
  credentials: PartnerCredentials,
  product: PartnersProducts
) => {
  return api
    .post(`${BASE_URL}/credentials/${product}`, credentials)
    .then(({ data }: AxiosResponse) => data);
};

export const removePartnerCredentials = (
  partner: string,
  password: string,
  product: PartnersProducts
) => {
  return api.delete(`${BASE_URL}/credentials/${product}`, {
    params: {
      partner,
      password
    }
  });
};

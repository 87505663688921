import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import Loader from "react-loader-spinner";
import BackIcon from "../images/icons/ic_back.png";
import {
  getLoanTransactions,
  getTransactionInfo,
  downloadReport,
  getStaircaseCollection,
} from "../service/staircaseService";
import {getAmericanDateFromDate, toUpperFirstChar} from "../util/helper";
import useInterval from "../hooks/useInterval";
import {initializeOriginationContext} from "../service/encompassService";

function TransactionListPage({data}) {
  console.log("TransactionListPage");
  const {host} = data;
  const location = useLocation();
  // var  domain = "russell-dev.staircaseapi.com";
  // var  apiKey = "73884068-3362-4899-b7db-4c72e0ac46b2";
  // var  verificationType = "employment";
  // var  transactionRequests = [{transactionId: "fe6dde4e-6a28-4512-8cd2-7ed978a0329d"}];
  var {transactionRequests, domain, apiKey, verificationType} = location.state;
  // let entityId = ""
  const [entityId, setEntityId] = useState("");
  const [transactionsResult, setTransactionsResult] = useState([]);
  const [detailsResult, setDetailsResult] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    const initPending = async () => {
      const initResult = await initializeOriginationContext(host);

      const entity_id = _.get(
          initResult.encompassOriginResult,
          "encompassOriginData.response_payload.entityRef.entityId",
          ""
      );
      setEntityId(entity_id);
      await loadTransactions(entity_id, 0);
      setLoading(false);
    };
    initPending();
  }, []);

  async function loadTransactions(entity_id, page) {
    setLoadingMore(true);
    await setCurrentPage(page)
    const res = await getLoanTransactions(domain, apiKey, entity_id, 10, page * 10);

    const transactions = _.get(
        res,
        "response_payload",
        null
    );

    const details = await Promise.all(
        transactions.map((tr) => {
          return getDetails(tr);
        })
    );

    const more = transactions.length % 10 === 0;
    const cTransactions = transactionsResult;
    const cDetails = detailsResult;
    setDetailsResult([...cDetails, ...details]);
    setTransactionsResult([...cTransactions, ...transactions]);
    setHasMore(more);
    setLoadingMore(false);
  }

  async function getDetails(transaction) {
    if (transaction.status === "completed") {
      let trRes = await getTransactionInfo(
          transaction.id,
          domain,
          apiKey
      );
      return trRes
    } else {
      return {"response_payload": {"response": {"status": "processing"}}}
    }
  }

  async function loadMoreButtonClicked() {
    const nextPage = currentPage + 1
    await loadTransactions(entityId, nextPage)
  }

  function getStatusColor(state) {
    console.log("getStatusColor", state);
    if (state === "completed") {
      return "#1E9C0A";
    } else if (state === "failed") {
      return "#D5320F";
    } else {
      return "#5E84FF";
    }
  }

  return (
      <>
        {loading === true ? (
            <Loader
                type="TailSpin"
                color="#00BFFF"
                height={120}
                width={120}
                id="loader"
                style={{
                  position: "absolute",
                  left: "46%",
                  top: "35%",
                  height: "31px",
                  width: "31px",
                }}
            />
        ) : (
            <div
                id="verification-result-container"
                className="pageContainer"
                style={{marginBottom: "200px"}}
            >

              <div className="verificationHeaderHolder">
                <p id="verificationHeader" className="verificationContentHeader">
                  Transaction History
                </p>
              </div>

              <div className="sectionContainer verification">
                <div id="topLeftDiv">
                  <p className="backText" onClick={() => window.history.back()}>
                    &#5176; Back
                  </p>
                </div>
                {
                  transactionsResult.map((item, index) => (
                      <div className="transactionListItem">
                        <div className="verification-row-2-6-1">
                      <span className="transactionListItemText">
                        {item.created.split("T")[0]}
                      </span>
                          <span className="transactionListItemText">
                        {item.product.name.replace("Test", "")}
                      </span>
                          <span className="transactionStatusText" style={{
                            color: getStatusColor(
                                detailsResult[index].response_payload.response.status
                            ),
                          }}>
                        {detailsResult[index].response_payload.response.status.toUpperCase() ?? ""}
                      </span>
                        </div>
                        {
                          detailsResult[index].response_payload?.response?.errors?.length > 0 ? (
                              <div className="verification-row-1">
                            <span className="transactionListItemFailText">
                              Fail Reason: {detailsResult[index].response_payload.response.errors[0].description.replace("$.response_payload.response_str", "Contact Staircase Support")}
                            </span>
                              </div>
                          ) : (<div/>)
                        }
                      </div>
                  ))
                }
                {hasMore && !loadingMore ? (<button
                    id="loadMoreButton"
                    onClick={loadMoreButtonClicked}
                >Load More</button>) : (<div/>)}
                {loadingMore === true ? (
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={24}
                        width={24}
                        id="loader"
                        style={{
                          position: "static",
                          marginLeft: "auto",
                          marginRight: "auto"
                        }}
                    />
                ) : (<div/>)}
              </div>
            </div>
        )}
      </>
  );
}

export default TransactionListPage;

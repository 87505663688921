import styled from 'styled-components';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { LogoutIcon } from 'shared/icons/LogoutIcon';
import Button from 'shared/ui/button';

export const OrangeLogoutIcon = styled(LogoutIcon)<SvgIconProps>`
  color: #ff9c40;
  margin: 24px auto 0;
  font-size: 72px;
`;

export const LogOutModalButton = styled(Button)`
  flex-basis: 169px;
  flex-grow: 1;
`;

import _ from "lodash";

async function getTransactionInfo(transactionId, domain, apiKey) {
  let data = {
    request_payload: {
      resource_id: transactionId,
    },
  };

  try {
    const res = await fetch(
      "https://" +
        domain +
        "/connector-jobs/vendors/encompass/lookups/get_encompass_transaction/jobs",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      const data = await res.json();

      console.log("getTransactionInfo", data);
      return data;
    } else if (res.status === 403) {
      throw Error("Unauthorized getting transaction info");
    } else {
      throw Error("Error on getting transaction info");
    }
  } catch (e) {
    throw Error(
      "Your Staircase credentials are not valid. Please contact your admin."
    );
  }
}

async function getLoanTransactions(domain, apiKey, entity_id, limit, start) {
  let data = {
    request_payload: {
      entity_id: entity_id,
      limit: limit,
      start: start,
    },
  };

  try {
    const res = await fetch(
      "https://" +
        domain +
        "/connector-jobs/vendors/encompass-epc/lookups/get_encompass_loan_transactions/jobs",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      const data = await res.json();

      console.log("getTransactionInfo", data);
      return data;
    } else if (res.status === 403) {
      throw Error("Unauthorized getting transaction info");
    } else {
      throw Error("Error on getting transaction info");
    }
  } catch (e) {
    throw Error(
      "Your Staircase credentials are not valid. Please contact your admin."
    );
  }
}

async function getPartners(domain, apiKey, product) {
  try {
    const res = await fetch("https://" + domain + "/" + product + "/partners", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
    });

    if (res.ok) {
      return res.json()
    } else if (res.status === 403) {
      console.error(
        "Your Staircase credentials are not valid. Please contact your admin."
      );
    } else {
      console.error("Error occured.", res);
    }
  } catch (e) {
    console.error(
      "Your Staircase credentials are not valid. Please contact your admin."
    );
  }
}

async function getStaircaseCollection(
  domain,
  apiKey,
  transactionId,
  collectionId
) {
  try {
    const res = await fetch(
      "https://" +
        domain +
        "/persistence/transactions/" +
        transactionId +
        "/collections/" +
        collectionId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      }
    );

    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.log("An error occurred while getting staircase collection", res);
    }
  } catch (e) {
    console.log("An error occurred while getting staircase collection", e);
  }
}

async function downloadReport(trId, domain, apiKey) {
  try {
    let data = {
      request_payload: {
        resource_id: trId,
      },
    };

    const res = await fetch(
      "https://" +
        domain +
        "/connector-jobs/vendors/encompass/lookups/get_encompass_transaction_resources/jobs",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      const data = await res.json();
      const entity = _.get(data, "response_payload[0]", null);
      if (entity) {
        const location = entity.location;
        const authorization = entity.authorization;

        const response = await fetch(location, {
          method: "GET",
          mode: "cors",
          headers: new Headers({
            Authorization: authorization,
          }),
        });
        const blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "report.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } else {
      console.log(res);
    }
  } catch (e) {
    console.error("Error while downloading report", e);
  }
}

export {
  getLoanTransactions,
  getTransactionInfo,
  getPartners,
  downloadReport,
  getStaircaseCollection,
};

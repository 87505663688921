import Tabs from '@material-ui/core/Tabs';

import { PartnersProducts } from 'api/partners';

import ContentContainer from 'layout/components/ContentContainer';
import ContentStack from 'layout/components/ContentStack';
import PageRail from 'layout/components/PageRail';
import PageContainer from 'layout/components/PageContainer';
import Tab, { PageTabContainer } from 'shared/ui/tab';
import { useTab } from 'shared/ui/tab/hooks/useTab';
import { Header } from 'shared/components/header';
import ContentTitle from 'shared/components/ContentTitle';
import { EmploymentPartnersTabPanel } from './components/EmploymentPartnersTabPanel';
import { IncomePartnersTabPanel } from './components/IncomePartnersTabPanel';
import { getTabA11yProps } from './utils';
import { useLocation } from "react-router-dom";

export default function Partners() {
  const { selectedTab, handleTabChange } = useTab();
  const location = useLocation();
  const { customerName, verificationType, loanIdentifier, domain, apiKey } = location.state;

  return (
    <PageContainer>
      <Header />

      <ContentTitle title="Configure Partners" backTitle="Back" backTo="/" />

      <PageRail spacingx="bottom" position="start">
        <ContentStack>
          <PageTabContainer>
            <ContentContainer>
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Borrower Products tabs"
              >
                {verificationType === "employment" ? <Tab {...getTabA11yProps(PartnersProducts.EMPLOYMENT)} /> : null}
                {verificationType === "income" ? <Tab {...getTabA11yProps(PartnersProducts.INCOME)} /> : null}
              </Tabs>
            </ContentContainer>
          </PageTabContainer>
        </ContentStack>

        <ContentContainer>

          {verificationType === "employment" ?
              <EmploymentPartnersTabPanel selectedIndex={selectedTab} index={0} location={location} />
              : null
          }
          {verificationType === "income" ?
              <IncomePartnersTabPanel selectedIndex={selectedTab} index={0} location={location}/>
              : null
          }

        </ContentContainer>
      </PageRail>
    </PageContainer>
  );
}

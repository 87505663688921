import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
export function DocumentCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none">
      <path
        d="M12.1548 2.15478C11.8423 1.84222 11.4183 1.66663 10.9763 1.66663H4.99999C4.08333 1.66663 3.34166 2.41663 3.34166 3.33329L3.33333 16.6666C3.33333 17.5833 4.07499 18.3333 4.99166 18.3333H15C15.9167 18.3333 16.6667 17.5833 16.6667 16.6666V7.35698C16.6667 6.91495 16.4911 6.49103 16.1785 6.17847L12.1548 2.15478ZM15 16.6666H4.99999V3.33329H10.8333V5.83329C10.8333 6.75377 11.5795 7.49996 12.5 7.49996H15V16.6666ZM7.93818 11.4604C7.61278 11.1365 7.0869 11.1362 6.76111 11.4597C6.43315 11.7853 6.43221 12.3155 6.75903 12.6423L8.52741 14.4107C8.85284 14.7361 9.38048 14.7361 9.70592 14.4107L13.2458 10.8708C13.5703 10.5463 13.5703 10.0203 13.2458 9.69579C12.9214 9.37133 12.3953 9.37133 12.0708 9.69579L9.12499 12.6416L7.93818 11.4604Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

import styled from 'styled-components';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiFormControl from '@material-ui/core/FormControl';
import { InputProps } from 'shared/ui/input';

export const TextFieldInputLabel = styled(({ hidden, ...props }) => (
  <MuiInputLabel {...props} />
))`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  position: static;
  transform: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 6px;
  color: #000;
`;

export const TextFieldFormHelperText = styled(MuiFormHelperText)`
  color: #000;
  font-size: 11px;
  line-height: 130%;
  margin-top: 4px;
  min-height: 14px;
`;

export const TextFieldSelect = styled(MuiSelect)<InputProps & SelectProps>`
  select:focus {
    background-color: #fafafa;
  }
`;

export const TextFieldSelectExpandMoreIcon = styled(ExpandMoreIcon)`
  cursor: pointer;
`;

export const FormControl = styled(MuiFormControl)`
  & + & {
    margin-top: 10px;
  }
`;

import React, { useEffect, useState } from "react";
import _, { get } from "lodash";
import Loader from "react-loader-spinner";

import { createTransaction } from "./byteServices";
import { checkRequiredFields } from "./../../util/requiredFields";
import { create_health_metric } from "./../../service/healthService";
import { getPartners } from "./../../service/staircaseService";
import InfoIcon from "./../../images/icons/ic_info.png";
import ArrowRight from "./../../images/icons/arrow_right.png";

import "./../../App.css";
import { useHistory } from "react-router-dom";
import { getGroupStatus } from "shared/utils/products";

const Verification = ({ params }) => {

    const history = useHistory()

    let [visibilityState, setVisibilityState] = useState(["hidden"]);

    const customerName = params.customerName
    const verificationType = params.verificationType
    const loanIdentifier = params.loanIdentifier
    const domain = params.domain
    const apiKey = params.apiKey

    const byteLanguage = "byte-to-staircase-borrower-mapping"

    const [parties, setParties] = useState([]);
    let [verificationData, setVerificationData] = useState([]);

    useEffect(() => {
        const initVerification = async () => {

            await invokeByteInputAdapter(domain, apiKey, verificationType, loanIdentifier)
            updateVerificationTypeLabel(verificationType)
            updatePartners(domain, apiKey)
            setLoading(false);
        };
        initVerification().catch((error) => {
            console.log('Verification catch block.')

        });
    }, []);

    function BorrowerEmploymentCheckbox(index) {

        console.log(borrowerEmployments)

        let tempEmployments = borrowerEmployments[index]

    };

    async function invokeByteInputAdapter(domain, apiKey, productFlowName, loanIdentifier) {

        try {
            let bodyData = {
                "product_flow_name": productFlowName,
                "request_data": {
                    "customer_transaction_id": loanIdentifier,
                    "loans": [
                        {
                            "with_loan_identifier": [
                                {
                                    "has_loan_identifier_value": {
                                        "has_value": loanIdentifier
                                    }
                                }
                            ]
                        }
                    ]
                }
            };
            const res = await fetch("https://" + domain + "/product/products/los-byte-input-adapter/invocations", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": apiKey,
                },
                body: JSON.stringify(bodyData),
            });

            if (res.ok) {
                let invocationResponse = await res.json()
                let invocationId = invocationResponse['invocation_id']
                let invocationStatus = invocationResponse['invocation_status']

                let responseCollection = await getByteInputAdapterResponseCollection(invocationId, invocationStatus)
                await processByteInputAdapterResponseCollection(responseCollection)

            } else if (res.status === 403) {
                console.error(
                    "Your Staircase credentials are not valid. Please contact your admin."
                );
            } else {
                console.error("Error occured.", res);
            }
        } catch (e) {
            console.error(
                "Your Staircase credentials are not valid. Please contact your admin."
            );
        }
    }

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    async function getByteInputAdapterResponseCollection(invocationId, invocationStatus) {

        let invocationStatusresponse = ""
        do {
            const res = await fetch("https://" + domain + "/product/products/los-byte-input-adapter/invocations/" + invocationId, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": apiKey,
                }
            });

            if (res.ok) {
                invocationStatusresponse = await res.json()
                invocationStatus = invocationStatusresponse["invocation_status"]
            }
            else if (res.status === 403) {
                console.error(
                    "Your Staircase credentials are not valid. Please contact your admin."
                );
            } else {
                console.error("Error occured.", res);
            }

            await timeout(2000);
        }
        while (invocationStatus !== "COMPLETED")

        return invocationStatusresponse
    }

    function processByteInputAdapterResponseCollection(byteInputAdapterResponseCollection) {

        let responseCollection = byteInputAdapterResponseCollection['response_collection']

        if (typeof responseCollection !== 'undefined') {
            console.log('Processing borrower data...')
            let taxpayerIdentifier
            /* try {
                taxpayerIdentifier = responseCollection.data.people[0].has_taxpayer_identifier_value.has_value;
                if (taxpayerIdentifier == "") {
                    console.log("Missing SSN. Cancel verification process.")
                }
            }
            catch {
                console.log("Missing SSN. Cancel verification process.")
            } */

            let tempParties = responseCollection.data;
            setParties(tempParties)
            if (tempParties.organizations.length > 0) {
                updateBorrowerEmployments(tempParties)
            }
        }
        else {
            console.log('There is no borrower data available.')
        }
    }

    async function updateVerificationTypeLabel(verificationType) {

        if (verificationType == 'income') {
            setVerificationTypeLabel("Income Verification");
        } else if (verificationType == 'employment') {
            setVerificationTypeLabel("Employment Verification");
        } else if (verificationType == 'assets') {
            setVerificationTypeLabel("Asset Verification");
        }
    }

    async function updatePartners(domain, apiKey) {
        let partners = await getPartners(domain, apiKey, verificationType);
        for (let p in partners) {
            partners[p]["partnerTitle"] = partners[p]["partner"].charAt(0).toUpperCase() + partners[p]["partner"].slice(1)
            if (partners[p]["active"] !== true)
                partners[p]["disabled"] = "disabled"
            else
                partners[p]["disabled"] = ""
        }
        setPartners(partners)
    }

    async function updateBorrowerEmployments(tempParties) {

        let tempBorrowerEmployments = []

        console.log(tempParties)

        let tempOrganizations = tempParties["organizations"]

        tempParties["people"].forEach(function (borrower, i) {
            try {
                let tempWorksFor = borrower.works_for
                tempWorksFor.forEach(function (orgId) {
                    console.log(orgId)
                    tempOrganizations.forEach(function (tempOrg) {
                        if (orgId == tempOrg["@id"]) {
                            let tempBorrowerDetails = {}
                            tempBorrowerDetails["employerName"] = tempOrg["has_organization_name"]["has_value"]
                            tempBorrowerDetails["employerId"] = tempOrg["has_organization_identifier"]["has_value"]
                            tempBorrowerDetails["borrowerId"] = i
                            tempBorrowerEmployments.push(tempBorrowerDetails)
                            setBorrowerEmployments(tempBorrowerEmployments)
                        }
                    });
                });
            }
            catch (e) {
                console.error(
                    "There is no borrower information available."
                );
                setBorrowerEmployments([])
            }
        });
    }

    const verificationButtonClicked = async function () {
        setVerifButtonClicked(true);

        var selectedPartner = document.getElementById("partnersSelect").value;

        const promiseArray = [];

        if (verificationData.length != 0) {
            verificationData.forEach(function (data, i) {

                let tempElementId = `checkbox-borrower-${data.borrower}`;

                let tempCheckBox = document.getElementById(tempElementId);

                if (tempCheckBox.checked) {
                    let tempEmployerId = data.employerId
                    let borrowerId = parties.people[data.borrower].has_borrower_identifier.has_value
                    let requestData = {
                        "request_payload": {
                            "file_data_id": loanIdentifier,
                            "partner_name": selectedPartner,
                            "employer_id": tempEmployerId,
                            "borrower_id": borrowerId
                        },
                    }

                    promiseArray.push(createTransaction(domain, apiKey, verificationType, requestData));
                }

            });

            transactionRequests = await Promise.all(promiseArray);
            console.log(transactionRequests)
            await create_health_metric(domain, apiKey, "STARTED", "", "", "logging", "Ordered the verification", "", "/")

            history.push({
                pathname: "/transactions",
                state: {
                    customerName,
                    verificationType,
                    loanIdentifier,
                    domain,
                    apiKey,
                },
            });
        }

        setVerifButtonClicked(false);
    };

    const selectedPartnerChange = async function () {
        var _selectedPartner = document.getElementById("partnersSelect").value;
        setSelectedPartner(_selectedPartner)
    }

    const partnersClicked = async function () {
        console.log("partners clicked");

        history.push({
            pathname: "/partners",
            state: {
                customerName,
                verificationType,
                loanIdentifier,
                domain,
                apiKey,
            },
        });
    };

    const transactionHistoryClicked = async function () {
        console.log("transaction history clicked");

        history.push({
            pathname: "/transactions",
            state: {
                customerName,
                verificationType,
                loanIdentifier,
                domain,
                apiKey,
            },
        });
    };

    function checkBorrowerEnabled(e, index) {

        let tempElementId = "sub-" + index
        if (e.target.checked) {
            setVisibilityState("visible")
            document.getElementById(tempElementId).style.visibility = "visible";
            document.getElementById(tempElementId).style.display = "";
        }
        else {
            setVisibilityState("hidden")
            document.getElementById(tempElementId).style.visibility = "hidden";
            document.getElementById(tempElementId).style.display = "none";

        }
    }

    function checkCoBorrowerEnabled(e, index) {

        let tempElementId = "sub-" + index
        if (e.target.checked) {
            setVisibilityState("visible")
            document.getElementById(tempElementId).style.visibility = "visible";
            document.getElementById(tempElementId).style.display = "";
        }
        else {
            setVisibilityState("hidden")
            document.getElementById(tempElementId).style.visibility = "hidden";
            document.getElementById(tempElementId).style.display = "none";

        }
    }

    function checkBorrowerEnabledDeprecated(e, index) {
        let newEnabled = [...isBorrowerEnabled];
        newEnabled[index] = e.target.checked;
        setIsBorrowerEnabled(newEnabled);

        if (e.target.checked) {
            setVerificationButtonEnabled(true)
        }
        else {
            setVerificationButtonEnabled(false)
        }
    }

    function checkCoborrowerEnabled(e, index) {
        if (isCoborrowerEnabled[index] === true && e.target.checked === false) {
            coborrowerEmployments[index].forEach((emp) => {
                emp.status = false;
            });
            setCoborrowerEmployments([...coborrowerEmployments]);
        }

        let newEnabled = [...isCoborrowerEnabled];
        newEnabled[index] = e.target.checked;
        setIsCoborrowerEnabled(newEnabled);
    }

    function checkMissingFields(encompassOriginData, index) {
        if (encompassOriginData && encompassOriginData.response_payload) {
            const missingFields = checkRequiredFields(
                encompassOriginData,
                isBorrowerEnabled,
                isCoborrowerEnabled,
                index
            );

            if (missingFields.length > 0) {
                return (
                    <div id="missing-fields-container" className="borrower missingInfo">
                        <div className="missing_info">
                            Missing Information
                        </div>
                        <ul>
                            {missingFields.map((field) => (
                                <li key={field}>
                                    <span>
                                        <b>{field}</b>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            }
            return null;
        }
    }

    function handleBorrowerEmploymentCheckbox(e, index, i, employerId) {

        let tempVerificationData = {}
        tempVerificationData["id"] = index.toString() + i.toString()
        tempVerificationData["borrower"] = index
        tempVerificationData["employer"] = i
        tempVerificationData["employerId"] = employerId

        try {

            if (verificationData.length == 0) {
                verificationData.push(tempVerificationData)
            }
            else {
                verificationData.forEach(function (data, i) {
                    if (data.id === tempVerificationData["id"]) {
                        console.log("Exists.")
                        verificationData.splice(i, 1);
                    }
                    else {
                        console.log("Does not exist.")
                        verificationData.push(tempVerificationData)
                    }
                });
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    let transactionRequests = [];

    const [encompassOriginResult, setEncompassOriginResult] = useState([]);
    const [originationContext, setOriginationContext] = useState([]);
    const [transactionObject, setTransactionObject] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [verifButtonClicked, setVerifButtonClicked] = useState(false);
    const [verificationButtonEnabled, setVerificationButtonEnabled] = useState(true);
    const [isBorrowerEnabled, setIsBorrowerEnabled] = useState([]);
    const [isCoborrowerEnabled, setIsCoborrowerEnabled] = useState([]);
    const [borrowerEmployments, setBorrowerEmployments] = useState([]);
    const [coborrowerEmployments, setCoborrowerEmployments] = useState([]);
    const [partners, setPartners] = useState([]);
    const [verificationTypeLabel, setVerificationTypeLabel] = useState("Income Verification");
    const [verificationTypeNew, setVerificationType] = useState("Employment Verification");
    const [pdfChecked, setPdfChecked] = useState(false);
    const [staircaseReportChecked, setStaircaseReportChecked] = useState(true);
    const [partnerReportChecked, setPartnerReportChecked] = useState(false);
    const [pdfFilename, setPdfFilename] = useState("");
    const [userSelectedResources, setUserSelectedResources] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState("default");

    return (
        <>
            {loading === true ? (
                <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={120}
                    width={120}
                    id="loader"
                    style={{
                        position: "absolute",
                        left: "46%",
                        top: "35%",
                        height: "31px",
                        width: "31px",
                    }}
                />
            ) : (
                <div id="verificationContainer" className="pageContainer">
                    <div className="verificationHeaderHolder">
                        <p id="verificationHeader" className="verificationContentHeader">
                            New {verificationTypeLabel}
                        </p>
                    </div>
                    <div className="sectionContainer-start">
                        <div className="partnerInfoHolder">
                            <div className="labelHolder">
                                <p className="selectLabel">Select your verification partner:</p>
                            </div>
                            <div className="partnerSelectHolder">
                                <select
                                    name="partnersSelect"
                                    id="partnersSelect"
                                    className="partnersSelect"
                                    onChange={selectedPartnerChange}
                                >
                                    {
                                        verificationType !== 'asset' ? (
                                            <option value="default">Waterfall</option>) : null
                                    }

                                    {
                                        partners.filter(function (p) {
                                            return !p["disabled"];
                                        }).map((value, key) =>
                                            <option className="partnersSelectOption"
                                                value={value["partner"]}
                                            >{value["partnerTitle"]}</option>
                                        )
                                    }
                                </select>
                                <button onClick={partnersClicked} className="partnerManageButton">
                                    <div className="partnerManageLabel">
                                        Manage Partners
                                    </div>
                                </button>
                                <button onClick={transactionHistoryClicked} className="transactionHistoryButton">
                                    <div className="transactionHistoryLabel">
                                        Transaction History
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div className="BorrowerInfoHolder">
                            {(parties["people"].length == 0) ? (<p>There are no borrower or coborrower information available.</p>) : (<p>Please select who you want to verify data from:</p>)}
                            {parties["people"].map((value, index) => {
                                let input_id = `checkbox-borrower-${index}`;
                                let input_id_co = `checkbox-coborrower-${index}`;
                                return <div className="borrower_coborrower">
                                    <div className="borrower top">
                                        <div className="borrowerCheckbox">
                                            <input
                                                type="checkbox"
                                                className="checkbox-borrower"
                                                id={input_id}
                                                onClick={(e) => checkBorrowerEnabled(e, index)}
                                            />
                                        </div>
                                        <div className="borrowerData">
                                            <div className="borrowerName">
                                                {
                                                    (index == 0) ? (<div className="boldedLabel">Borrower: </div>) : (<div className="boldedLabel">Co-Borrower: </div>)
                                                }
                                                {
                                                    (value["has_first_name"]["has_value"].length > 0 && value["has_last_name"]["has_value"].length > 0) ?
                                                        (
                                                            <div className="simplelabel">{value["has_first_name"]["has_value"]} {value["has_last_name"]["has_value"]}</div>
                                                        ) :
                                                        (
                                                            <div className="simplelabel missing">Missing</div>
                                                        )
                                                }
                                            </div>


                                            <div className="borrowerAddress">
                                                <div className="boldedLabel">Address: </div>
                                                {
                                                    (getBorrowerAddress(index, parties)) ?
                                                        (
                                                            <div className="simplelabel">{getBorrowerAddress(index, parties)}</div>
                                                        ) :
                                                        (
                                                            <div className="simplelabel missing">Missing</div>
                                                        )
                                                }
                                            </div>

                                            <div id={"sub-".concat(index)} className="borrowerBottom">
                                                <div className="borrowerDivider">
                                                    <div className="borrowerDividerRectangle">
                                                    </div>
                                                </div>
                                                <div className="borrowerCheckboxes">
                                                    <div className="borrowerEmployer">
                                                        Employer:
                                                    </div>
                                                    {borrowerEmployments.map((item, i) => {
                                                        return (
                                                            (item.borrowerId == index)?
                                                            (
                                                                <div className="borrowerEmployments">
                                                                <div
                                                                    key={`borrowerEmployment-${index}-${i}`}
                                                                    className="verification-block-1-10"
                                                                    style={{ marginTop: "10px" }}
                                                                >
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-employment"
                                                                            key={`cb-${index}${i}`}
                                                                            id={`${index}${i}`}
                                                                            onClick={(e) => handleBorrowerEmploymentCheckbox(e, index, i, item.employerId)}
                                                                        />
                                                                    </div>
                                                                    <div className="verificationValueDiv">
                                                                        <span className="verificationValue">{item.employerName}</span>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            ):(<div></div>)
                                                            )
                                                    })}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            })}
                        </div>

                    </div>

                    <div id="buttons_holder" className="sectionContainer-start">
                        {/*<button*/}
                        {/*  id="cancelButton"*/}
                        {/*  onClick={() => closeInteraction(host)}*/}
                        {/*  style={{ marginTop: "30px" }}*/}
                        {/*>*/}
                        {/*  Cancel*/}
                        {/*</button>*/}
                        <button
                            id="verificationButton"
                            onClick={verificationButtonClicked}
                            disabled={!verificationButtonEnabled || verifButtonClicked}
                        >
                            Start Verification
                            {verifButtonClicked === true ? (
                                <Loader
                                    type="TailSpin"
                                    color="#FFFFFF"
                                    height={20}
                                    width={20}
                                    id="loader"
                                    style={{
                                        position: "relative",
                                        left: "5%",
                                    }}
                                />
                            ) : (
                                <div className="nextArrowHolder">
                                    <img src={ArrowRight} className="nextArrowIcon" />
                                </div>
                            )
                            }
                        </button>
                    </div>
                </div>


            )}
        </>
    );
}

function getBorrowerCompanyName(borrowerData) {
    try {
        let companyName = borrowerData.roles.role[0].borrower.employers.employer[0].legal_entity.full_name
        if (typeof companyName !== 'undefined') {
            return companyName
        }
        else {
            return false
        }
    }
    catch {
        return false
    }
}

function getBorrowerAddress(index, parties) {
    try {
        let borrowerData
        console.log(parties)

        try {
            let addressLineOneText = parties.addresses[index].has_address_line_1_text.has_value
            let addressLineTwoText = parties.addresses[index].has_address_line_2_text.has_value
            let cityName = parties.addresses[index].has_city_name.has_value
            let countryName = parties.addresses[index].has_country_name.has_value
            let postalCode = parties.addresses[index].has_postal_code.has_value
            let stateCode = parties.addresses[index].has_state_code.has_value

            return addressLineOneText + ', ' + addressLineTwoText + ' ' + cityName + ', ' + countryName + ', ' + postalCode + ', ' + stateCode
        }
        catch (e) {
            return false
        }
    }
    catch {
        return false
    }
}

export default Verification;

import _ from "lodash";

const requiredFieldList = [
  // borrower required fields
  // {
  //   path: "response_payload.loan.applications[0].borrower.firstName",
  //   encompassName: "Borrower First Name",
  //   type: "string",
  //   borrowerType: "borrower",
  // },
  // {
  //   path: "response_payload.loan.applications[0].borrower.lastName",
  //   encompassName: "Borrower Last Name",
  //   type: "string",
  //   borrowerType: "borrower",
  // },
  {
    path: "response_payload.loan.applications[0].borrower.taxIdentificationIdentifier",
    encompassName: "Borrower SSN",
    type: "string",
    borrowerType: "borrower",
  },
  {
    path: "response_payload.loan.applications[0].borrower.birthDate",
    encompassName: "Borrower Birth Date",
    type: "string",
    borrowerType: "borrower",
  },
  {
    path: "response_payload.loan.applications[0].borrower.emailAddressText",
    encompassName: "Borrower Email Address",
    type: "string",
    borrowerType: "borrower",
  },
  {
    path: "response_payload.loan.applications[0].borrower.mobilePhone",
    encompassName: "Borrower Mobile Phone",
    type: "string",
    borrowerType: "borrower",
  },
  // {
  //   path: "response_payload.loan.applications[0].borrower.residences",
  //   encompassName: "Borrower Address",
  //   type: "residence",
  //   borrowerType: "borrower",
  // },
  {
    path: "response_payload.loan.applications[0].borrower.employment",
    encompassName: "Borrower Employer",
    type: "string",
    borrowerType: "borrower",
  },
  // coborrower required fields
  // {
  //   path: "response_payload.loan.applications[0].coborrower.firstName",
  //   encompassName: "Coborrower First Name",
  //   type: "string",
  //   borrowerType: "coborrower",
  // },
  // {
  //   path: "response_payload.loan.applications[0].coborrower.lastName",
  //   encompassName: "Coborrower Last Name",
  //   type: "string",
  //   borrowerType: "coborrower",
  // },
  {
    path: "response_payload.loan.applications[0].coborrower.taxIdentificationIdentifier",
    encompassName: "Coborrower SSN",
    type: "string",
    borrowerType: "coborrower",
  },
  {
    path: "response_payload.loan.applications[0].coborrower.birthDate",
    encompassName: "Coborrower Birth Date",
    type: "string",
    borrowerType: "coborrower",
  },
  {
    path: "response_payload.loan.applications[0].coborrower.emailAddressText",
    encompassName: "Coborrower Email Address",
    type: "string",
    borrowerType: "coborrower",
  },
  {
    path: "response_payload.loan.applications[0].coborrower.mobilePhone",
    encompassName: "Coborrower Mobile Phone",
    type: "string",
    borrowerType: "coborrower",
  },
  // {
  //   path: "response_payload.loan.applications[0].coborrower.residences",
  //   encompassName: "Coborrower Address",
  //   type: "residence",
  //   borrowerType: "coborrower",
  // },
  {
    path: "response_payload.loan.applications[0].coborrower.employment",
    encompassName: "Coborrower Employer",
    type: "string",
    borrowerType: "coborrower",
  },
];

function checkRequiredFields(
  encompassOriginData,
  isBorrowerEnabled,
  isCoborrowerEnabled,
  index
) {
  let fieldList = [];
  fieldList.push(
    ...requiredFieldList.filter((el) => {
      if (isBorrowerEnabled) {
        return el.borrowerType === "borrower";
      }
    })
  );

  fieldList.push(
    ...requiredFieldList.filter((el) => {
      if (isCoborrowerEnabled) {
        return el.borrowerType === "coborrower";
      }
    })
  );
  const regex = /\[[0-9]\]/i;
  for(let a in fieldList){
    fieldList[a].path = fieldList[a].path.replace(regex, "["+index+"]")
  }

  let has_coborrower = false;
  let coborrower_name = _.get(
      encompassOriginData,
      "response_payload.loan.applications[0].coborrower.firstName",
      "") + _.get(
      encompassOriginData,
      "response_payload.loan.applications[0].coborrower.lastName",
      "");

  if(coborrower_name.length > 1) {
    has_coborrower = true;
  }
  const missingFields = [];
  fieldList
    .filter((requiredField) => requiredField.type === "string")
    .forEach((requiredField) => {
      const field = _.get(encompassOriginData, requiredField.path, null);
      if (!field) {
        if((has_coborrower && requiredField.borrowerType === "coborrower") || requiredField.borrowerType === "borrower")
          missingFields.push(requiredField.encompassName);
      }
    });

  fieldList
    .filter((requiredField) => requiredField.type === "employment")
    .forEach((requiredField) => {
      const employmentObj = _.get(
        encompassOriginData,
        requiredField.path,
        null
      );
      if (employmentObj) {
        let employmentExist = false;
        employmentObj.forEach((employment) => {
          if (employment.employerName) {
            employmentExist = true;
          }
        });
        if (!employmentExist) {
          if((has_coborrower && requiredField.borrowerType === "coborrower") || requiredField.borrowerType === "borrower")
            missingFields.push(requiredField.encompassName);
        }
      }
    });

  fieldList
    .filter((requiredField) => requiredField.type === "residence")
    .forEach((requiredField) => {
      const residenceObj = _.get(encompassOriginData, requiredField.path, null);
      let residenceExist = false;
      if (residenceObj) {
        residenceObj.forEach((residence) => {
          if (
            residence.addressCity &&
            residence.addressPostalCode &&
            residence.addressState &&
            residence.urla2020StreetAddress
          ) {
            residenceExist = true;
          }
        });
      }
      if (!residenceExist) {
        if((has_coborrower && requiredField.borrowerType === "coborrower") || requiredField.borrowerType === "borrower")
          missingFields.push(requiredField.encompassName);
      }
    });
  return missingFields;
}

export { checkRequiredFields };

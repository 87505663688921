import styled from 'styled-components';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

const StyledRouterLink = styled(RouterLink)`
  font-size: 12px;
  line-height: 130%;
  font-weight: 500;
`;

export type LinkProps = Pick<
  RouterLinkProps,
  'to' | 'children' | 'className' | 'onClick'
>;

export default function Link({ to, children, className, onClick }: LinkProps) {
  return (
    <MuiLink
      onClick={onClick}
      className={className}
      component={StyledRouterLink}
      to={to}
    >
      {children}
    </MuiLink>
  );
}

import styled from 'styled-components';
import MuiButton, {
  ButtonProps as MuiButtonProps
} from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
}

const getPosition = (size: number) => `calc(50% - ${size / 2}px)`;
export const ButtonSpinner = styled(CircularProgress)`
  position: absolute;
  top: ${({ size }) => getPosition(Number(size))};
  left: ${({ size }) => getPosition(Number(size))};
`;

export const ButtonLabel = styled.span``;

export const Button = styled(({ isLoading, ...props }) => (
  <MuiButton {...props} />
))<ButtonProps>`
  height: ${(props) => (props.size === 'small' ? 32 : 44)}px;
  padding: ${(props) => (props.size === 'small' ? '8px 20px' : '14px 24px')};
  font-size: ${(props) => (props.size === 'small' ? 12 : 14)}px;
  line-height: ${(props) => (props.size === 'small' ? '130%' : '16px')};
  font-weight: 500;
  border-radius: 6px;
  text-align: center;
  text-transform: none;
  position: relative;
  ${ButtonLabel} {
    color: ${({ isLoading }) => (isLoading ? 'transparent' : 'inherit')};
  }

  ${ButtonSpinner} {
    display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
    visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};
    svg {
      color: inherit;
    }
  }
`;

import { usePartnerActions } from 'pages/partners/hooks/usePartnerActions';
import { PartnersProducts } from 'api/partners';
import {
  ProductPartnersTabPanel,
  ProductPartnersTabPanelProps
} from './ProductPartnersTabPanel';

interface IncomePartnersTabPanelProps
  extends Pick<ProductPartnersTabPanelProps, 'index' | 'selectedIndex' | 'location'> {}

export function IncomePartnersTabPanel(props: IncomePartnersTabPanelProps) {
  const {
    isLoading: isLoadingIncomePartners,
    partners: incomePartners,
    movePartner: moveIncomePartner,
    updatePartnerStatus: updateIncomePartnerStatus,
    addPartnerCredential,
    removePartnerCredentials,
    partnerWebhookUrl
  } = usePartnerActions(PartnersProducts.INCOME, props.location);

  const productListSubTitle =
    '\n Staircase provides a list of partners that you can use to verify income across the W2 and W9 platforms and enable Borrowers to connect their payroll accounts.\n' +
      'You can set the verification order using the arrows.';
  const productAboutDescription =
    'Staircase provides a list of partners that you can use to verify income across the W2 and W9 platforms and enable Borrowers to connect their payroll accounts.';

  return (
    <ProductPartnersTabPanel
      {...props}
      isLoading={isLoadingIncomePartners}
      partnerWebhookUrl={partnerWebhookUrl}
      productPartners={incomePartners}
      productListSubTitle={productListSubTitle}
      productAboutDescription={productAboutDescription}
      onMovePartner={moveIncomePartner}
      onUpdatePartnerStatus={updateIncomePartnerStatus}
      onAddPartnerCredential={addPartnerCredential}
      onRemovePartnerCredentials={removePartnerCredentials}
    />
  );
}

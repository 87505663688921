import styled from 'styled-components';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import { IconProps } from '@material-ui/core/Icon';
import Link, { LinkProps } from 'shared/ui/link';

const ChevronLeftIcon = styled(ChevronLeftRoundedIcon)<IconProps>`
  font-size: 18px;
`;

interface StyledLinkProps extends LinkProps {
  size?: 'small' | 'medium';
}

const StyledLink = styled(({ size, ...props }) => (
  <Link {...props} />
))<StyledLinkProps>`
  cursor: pointer;
  font-size: ${({ size }) => (size === 'small' ? 12 : 16)}px;
  line-height: ${({ size }) => (size === 'small' ? 16 : 20)}px;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

interface BackButtonProps extends StyledLinkProps {
  text: string;
}
export function BackButton({
  text,
  to = '#',
  size = 'small',
  onClick,
  ...extra
}: BackButtonProps) {
  return (
    <StyledLink to={to} size={size} onClick={onClick} {...extra}>
      <ChevronLeftIcon fontSize="small" />
      <span>{text}</span>
    </StyledLink>
  );
}

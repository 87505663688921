import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';

interface InputLeftIconProps {
  iconComponent: ReactNode;
  position?: 'start' | 'end';
}

const StyledInputAdornment = styled(InputAdornment)`
  & > :first-child {
    max-width: 20px;
    max-height: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 50%;
  }
  margin-right: ${({ position }) => (position === 'start' ? '10px' : 0)};
  margin-left: ${({ position }) => (position === 'end' ? '10px' : 0)};

  & + & {
    margin-left: ${({ position }) => (position === 'end' ? '12px' : 0)};
  }
`;

export function InputIcon({
  iconComponent,
  position = 'start'
}: PropsWithChildren<InputLeftIconProps>) {
  return (
    <StyledInputAdornment position={position}>
      {iconComponent}
    </StyledInputAdornment>
  );
}

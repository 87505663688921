import styled from 'styled-components';

import { BP, GUTTERS, CONTENT_SPACING } from 'layout/constants';

type PageRailProps = {
  spacingx?: 'top' | 'bottom' | 'both';
  position: 'start' | 'center';
};

const PageRail = styled.div`
  display: ${({ position }: PageRailProps) =>
    position === 'center' ? 'flex' : 'block'};
  flex-direction: ${({ position }: PageRailProps) =>
    position === 'center' ? 'column' : 'unset'};
  min-height: ${({ position }: PageRailProps) =>
    position === 'center' ? '100%' : 'unset'};
  height: min-content;
  justify-content: ${({ position }: PageRailProps) =>
    position === 'center' ? 'center' : 'unset'};
  padding-bottom: ${({ spacingx }: PageRailProps) =>
    spacingx === 'bottom' || spacingx === 'both'
      ? CONTENT_SPACING.xs + 'px'
      : 0};
  padding-top: ${({ spacingx }: PageRailProps) =>
    spacingx === 'top' || spacingx === 'both' ? CONTENT_SPACING.xs + 'px' : 0};

  @media (min-width: ${BP.sm}px) {
    padding-bottom: ${({ spacingx }: PageRailProps) =>
      spacingx === 'bottom' || spacingx === 'both'
        ? CONTENT_SPACING.sm + 'px'
        : 0};
    padding-top: ${({ spacingx }: PageRailProps) =>
      spacingx === 'top' || spacingx === 'both'
        ? CONTENT_SPACING.sm + 'px'
        : 0};
  }

  @media (min-width: ${BP.md}px) {
    padding-bottom: ${({ spacingx }: PageRailProps) =>
      spacingx === 'bottom' || spacingx === 'both' ? GUTTERS.md + 'px' : 0};
    padding-top: ${({ spacingx }: PageRailProps) =>
      spacingx === 'top' || spacingx === 'both' ? GUTTERS.md + 'px' : 0};
  }

  @media (min-width: ${BP.lg}px) {
    padding-bottom: ${({ spacingx }: PageRailProps) =>
      spacingx === 'bottom' || spacingx === 'both' ? GUTTERS.lg + 'px' : 0};
    padding-top: ${({ spacingx }: PageRailProps) =>
      spacingx === 'top' || spacingx === 'both' ? GUTTERS.lg + 'px' : 0};
  }

  @media (min-width: ${BP.xl}px) {
    padding-bottom: ${({ spacingx }: PageRailProps) =>
      spacingx === 'bottom' || spacingx === 'both' ? GUTTERS.xl + 'px' : 0};
    padding-top: ${({ spacingx }: PageRailProps) =>
      spacingx === 'top' || spacingx === 'both' ? GUTTERS.xl + 'px' : 0};
  }
`;

export default PageRail;

import axios from 'axios';

import { API_URL } from 'shared/constants/url';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    "x-api-key": "73884068-3362-4899-b7db-4c72e0ac46b2"
  }
});
export default axiosInstance;

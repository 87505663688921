import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import Loader from "react-loader-spinner";
import {
  getTransactionInfo,
  downloadReport,
} from "../service/staircaseService";
import { getAmericanDateFromDate, toUpperFirstChar } from "../util/helper";
import useInterval from "../hooks/useInterval";

function MultipleVerificationResultPage({ data }) {
  console.log("MultipleVerificationResultPage");
  const location = useLocation();
  const { transactionRequests, domain, apiKey, verificationType } =
    location.state;
  const [transactionsResults, setTransactionsResults] = useState([]);
  const [downloadButtonStates, setDownloadButtonStates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initPending = async () => {
      const res = await Promise.all(
        transactionRequests.map((tr) => {
          return getTransactionInfo(tr.transactionId, domain, apiKey);
        })
      );

      console.log("initPending", res);
      setTransactionsResults([...res]);
      setLoading(false);
    };
    initPending();
  }, []);

  useInterval(async () => {
    const res = await Promise.all(
      transactionsResults
        .filter((tr) => tr.response_payload.status === "pending")
        .map((tr) => {
          return getTransactionInfo(tr.response_payload.id, domain, apiKey);
        })
    );

    res.forEach((tr) => {
      const index = transactionsResults.findIndex(
        (el) => el.response_payload.id === tr.response_payload.id
      );
      transactionsResults[index] = tr;
    });

    console.log("poll", transactionsResults);
    setTransactionsResults([...transactionsResults]);
  }, 10000);

  function addEmploymentVerificationElement(transaction) {
    const id = _.get(transaction, "response_payload.id", "");
    const options = _.get(transaction, "response_payload.request.options", "");
    const employerName = options["employerName"];
    const created = getAmericanDateFromDate(
      transaction["response_payload"]["created"]
    );
    const verifStatus = toUpperFirstChar(
      transaction["response_payload"]["status"]
    );
    let statusColor = verifStatus === "Pending" ? "#5E84FF" : "#3CD070";
    const downloadButton = (
      <div className="verification-block-1-5">
        <div className="verificationValueDiv">
          <button
            id={id}
            onClick={async () => {
              setDownloadButtonStates({ ...downloadButtonStates, [id]: true });
              await downloadReport(id, domain, apiKey);
              setDownloadButtonStates({ ...downloadButtonStates, [id]: false });
            }}
            className="downloadButton"
            disabled={downloadButtonStates[id]}
          >
            Download
            {downloadButtonStates[id] === true ? (
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={20}
                width={20}
                id="loader"
                style={{
                  position: "relative",
                  left: "5%",
                }}
              />
            ) : null}
          </button>
        </div>
      </div>
    );

    const emptyDiv = <div className="verification-block-1-5"></div>;

    return (
      <div
        className="verificationResultContent"
        style={{ marginBottom: "20px" }}
      >
        <div className="verification-row">
          <div className="verification-block-1-2">
            <div>
              <span className="verificationLabel">Company:</span>
            </div>
            <div className="verificationValueDiv">
              <span className="verificationValue">{employerName}</span>
            </div>
          </div>
          <div className="verification-block-1-5">
            <div>
              <span className="verificationLabel">Status:</span>
            </div>
            <div className="verificationValueDiv">
              <span
                className="verificationValue"
                style={{ color: statusColor }}
              >
                {verifStatus}
              </span>
            </div>
          </div>
        </div>
        <div className="verification-row">
          <div className="verification-block-1-2">
            <div>
              <span className="verificationLabel">Request Date:</span>
            </div>
            <div className="verificationValueDiv">
              <span className="verificationValue">{created}</span>
            </div>
          </div>
          {verifStatus === "Completed" ? downloadButton : emptyDiv}
        </div>
      </div>
    );
  }

  return (
    <>
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div id="temp-verification-result-container" className="pageContainer">
          <div className="sectionContainer">
            <div id="tempVerificationBorrowerContainer">
              <div className="verificationHeaderContainer">
                <p className="verificationContentHeader">
                  Borrower Verification
                </p>
              </div>

              <div className="verificationResultContent">
                <div className="verification-row">
                  <div className="verification-block-2-5">
                    <div>
                      <span className="verificationLabel">First Name:</span>
                    </div>
                    <div className="verificationValueDiv">
                      <span
                        id="tempBorrowerFirstName"
                        className="verificationValue"
                      >
                        {_.get(
                          transactionsResults[0],
                          "response_payload.request.loan.applications[0].borrower.firstName",
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="verification-block-2-5">
                    <div>
                      <span className="verificationLabel">Last Name:</span>
                    </div>
                    <div className="verificationValueDiv">
                      <span
                        id="tempBorrowerLastName"
                        className="verificationValue"
                      >
                        {_.get(
                          transactionsResults[0],
                          "response_payload.request.loan.applications[0].borrower.lastName",
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="verification-row-1">
                  <div className="verification-block-2-7">
                    <div>
                      <span className="verificationLabel">
                        Current Company:
                      </span>
                    </div>
                    <div className="verificationValueDiv">
                      <span
                        id="tempBorrowerCompany"
                        className="verificationValue"
                      >
                        {_.get(
                          transactionsResults[0],
                          "response_payload.request.loan.applications[0].borrower.employment",
                          []
                        )
                          .filter(
                            (emp) => emp.currentEmploymentIndicator === true
                          )
                          .reduce(
                            (prev, curr) => prev + ", " + curr.employerName,
                            ""
                          )
                          .substring(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                <span
                  className="verificationLabel"
                  id="borrowerResultVerifHeaderType"
                >
                  {verificationType}
                </span>
              </div>

              <div id="borrowerEmploymentResultsContainer">
                {transactionsResults
                  .filter((tr) => {
                    const options = _.get(
                      tr,
                      "response_payload.request.options",
                      ""
                    );
                    return options["borrowerType"] === "borrower";
                  })
                  .map((tr) => addEmploymentVerificationElement(tr))}
              </div>
            </div>

            <div id="tempVerificationCoborrowerContainer">
              <div
                className="verificationHeaderContainer"
                style={{ marginTop: "20px" }}
              >
                <p className="verificationContentHeader">
                  Coborrower Verification
                </p>
              </div>

              <div className="verificationResultContent">
                <div className="verification-row">
                  <div className="verification-block-2-5">
                    <div>
                      <span className="verificationLabel">First Name:</span>
                    </div>
                    <div className="verificationValueDiv">
                      <span
                        id="tempCoborrowerFirstName"
                        className="verificationValue"
                      >
                        {_.get(
                          transactionsResults[0],
                          "response_payload.request.loan.applications[0].coborrower.firstName",
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="verification-block-2-5">
                    <div>
                      <span className="verificationLabel">Last Name:</span>
                    </div>
                    <div className="verificationValueDiv">
                      <span
                        id="tempCoborrowerLastName"
                        className="verificationValue"
                      >
                        {_.get(
                          transactionsResults[0],
                          "response_payload.request.loan.applications[0].coborrower.lastName",
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="verification-row-1">
                  <div className="verification-block-2-7">
                    <div>
                      <span className="verificationLabel">
                        Current Company:
                      </span>
                    </div>
                    <div className="verificationValueDiv">
                      <span
                        id="tempCoborrowerCompany"
                        className="verificationValue"
                      >
                        {_.get(
                          transactionsResults[0],
                          "response_payload.request.loan.applications[0].coborrower.employment",
                          []
                        )
                          .filter(
                            (emp) => emp.currentEmploymentIndicator === true
                          )
                          .reduce(
                            (prev, curr) => prev + ", " + curr.employerName,
                            ""
                          )
                          .substring(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                <span
                  className="verificationLabel"
                  id="coborrowerResultVerifHeaderType"
                >
                  {verificationType}
                </span>
              </div>

              <div id="coborrowerEmploymentResultsContainer">
                {transactionsResults
                  .filter((tr) => {
                    const options = _.get(
                      tr,
                      "response_payload.request.options",
                      ""
                    );
                    return options["borrowerType"] === "coborrower";
                  })
                  .map((tr) => addEmploymentVerificationElement(tr))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MultipleVerificationResultPage;

import styled from 'styled-components';

export const PartnerIndexContainer = styled(({ disabled, ...props }) => (
  <span {...props} />
))`
  color: white;
  background-color: ${(props) => (props.disabled ? '#bebebe' : '#5e84ff')};
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 12px;
  margin: 12px;
  flex-grow: 0;
`;

interface PartnerIndexProps {
  index: number;
  disabled?: boolean;
}

export function PartnerIndex({ index, disabled }: PartnerIndexProps) {
  return (
    <PartnerIndexContainer disabled={disabled}>{index}</PartnerIndexContainer>
  );
}
